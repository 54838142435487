import React from "react";
import styled from "styled-components";

import Button from "@finanzchef24gmbh/design-system/src/Button";
import ChevronRightIcon from "@finanzchef24gmbh/design-system/src/Icons/ChevronRightIcon";
import Spacings from "@finanzchef24gmbh/design-system/src/Spacings";

const StyledHighlightedIndustryButton = styled(Button)`
  width: 100%;
  box-shadow: none;
  text-align: left;
`;

const StyledHighlightedIndustryName = styled.div`
  flex: 1;
`;

type HighlightedIndustryButtonProps = {
  name: string;
} & React.ComponentProps<typeof Button>;

const HighlightedIndustryButton: React.FC<
  React.PropsWithChildren<HighlightedIndustryButtonProps>
> = ({ name, ...buttonProps }) => (
  <StyledHighlightedIndustryButton type="button" {...buttonProps}>
    <Spacings.Inline alignItems="center">
      <StyledHighlightedIndustryName
        dangerouslySetInnerHTML={{
          __html: name,
        }}
      />

      <ChevronRightIcon color="brand" />
    </Spacings.Inline>
  </StyledHighlightedIndustryButton>
);

export default HighlightedIndustryButton;
