import styled, { css } from "styled-components";

import Layer from "../Layer";

export type CardProps = {
  isHighlighted?: boolean;
  isInteractive?: boolean;
};

const Card = styled(Layer.Raised)<CardProps>`
  border-radius: ${(props) => props.theme.borders.radius.medium}px;
  border-width: ${(props) =>
    props.isHighlighted || props.isInteractive
      ? props.theme.borders.width.medium
      : 0}px;
  border-style: solid;
  border-color: ${(props) =>
    props.isHighlighted ? props.theme.palette.brand.dark : "transparent"};
  ${(props) =>
    props.isInteractive &&
    css`
      .device-can-hover &&:hover {
        box-shadow: 0 0 0 4px ${props.theme.palette.backgrounds.transparent};
      }
    `};
`;

export default Card;
