import MarkdownToJsx from "markdown-to-jsx";
import React from "react";
import styled, { css } from "styled-components";

import RatingPositiveIcon from "../Icons/RatingPositiveIcon";
import ListItem from "../ListItem";
import Spacings from "../Spacings";
import Text, { lineHeightByTextStyle, TextPriority, TextStyles } from "../Text";

const headlineStyle = (tag: string, textStyle: TextStyles) => css`
  ${tag} {
    margin-bottom: ${(props) =>
      0.5 * lineHeightByTextStyle(props.theme)[textStyle]}rem;
  }
`;

type StyledMarkdownProps = {
  showListIcon?: boolean;
};

const StyledMarkdown = styled.div<StyledMarkdownProps>`
  ${/* sc-declaration */ headlineStyle("h1", "headline1")};
  ${/* sc-declaration */ headlineStyle("h2", "headline2")};
  ${/* sc-declaration */ headlineStyle("h3", "headline3")};
  ${/* sc-declaration */ headlineStyle("h4", "headline4")};
  ${/* sc-declaration */ headlineStyle("h5", "headline5")};
  ${/* sc-declaration */ headlineStyle("h6", "headline6")};

  ul {
    list-style: disc;

    ${(props) =>
      !props.showListIcon &&
      css`
        margin-left: ${props.theme.spacings.medium};
      `};
  }
  p + p,
  p + ul,
  ul + p {
    margin-top: ${(props) => props.theme.spacings.small};
  }
`;

type MarkdownProps = {
  children: string;
  isOnDarkBackground?: boolean;
  showListIcon?: boolean;
  priority?: TextPriority;
  avoidOverrides?: boolean;
  bodyTextStyle?: "body" | "bodyProlonged" | "caption";
};

const Markdown: React.FC<React.PropsWithChildren<MarkdownProps>> = ({
  children,
  isOnDarkBackground,
  showListIcon = false,
  priority,
  avoidOverrides,
  bodyTextStyle = "body",
  ...props
}) => (
  <StyledMarkdown showListIcon={showListIcon} {...props}>
    <MarkdownToJsx
      options={{
        overrides: avoidOverrides
          ? {}
          : {
              h1: {
                component: Text,
                props: {
                  isOnDarkBackground,
                  isUncropped: true,
                  textStyle: "headline1",
                },
              },
              h2: {
                component: Text,
                props: {
                  isOnDarkBackground,
                  isUncropped: true,
                  textStyle: "headline2",
                },
              },
              h3: {
                component: Text,
                props: {
                  isOnDarkBackground,
                  isUncropped: true,
                  textStyle: "headline3",
                },
              },
              h4: {
                component: Text,
                props: {
                  isOnDarkBackground,
                  isUncropped: true,
                  textStyle: "headline4",
                },
              },
              h5: {
                component: Text,
                props: {
                  isOnDarkBackground,
                  isUncropped: true,
                  textStyle: "headline5",
                },
              },
              h6: {
                component: Text,
                props: {
                  isOnDarkBackground,
                  isUncropped: true,
                  textStyle: "headline6",
                },
              },
              li: showListIcon
                ? {
                    component: ListItem,
                    props: {
                      alignItems: "flex-start",
                      as: "li",
                      left: <RatingPositiveIcon color="brand" />,
                      priority,
                      scale: "small",
                    },
                  }
                : {
                    component: Text,
                    props: {
                      as: "li",
                      isOnDarkBackground,
                      isUncropped: true,
                      priority,
                      textStyle: bodyTextStyle,
                    },
                  },
              p: {
                component: Text,
                props: {
                  as: "p",
                  isOnDarkBackground,
                  isUncropped: true,
                  priority,
                  textStyle: bodyTextStyle,
                },
              },
              span: {
                component: Text,
                props: {
                  as: "p",
                  isOnDarkBackground,
                  isUncropped: true,
                  priority,
                  textStyle: bodyTextStyle,
                },
              },
              strong: {
                component: Text,
                props: {
                  as: "span",
                  isOnDarkBackground,
                  isStrong: true,
                  isUncropped: true,
                  priority,
                  textStyle: bodyTextStyle,
                },
              },
              ul: {
                component: Spacings.Stack,
                props: {
                  as: "ul",
                },
              },
            },
      }}
    >
      {children}
    </MarkdownToJsx>
  </StyledMarkdown>
);

export default Markdown;
