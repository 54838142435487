import { useLayoutEffect, useRef, useState } from "react";

export type DialogState = {
  visible: boolean;
  toggle: {
    ref: React.MutableRefObject<HTMLButtonElement | null>;
    ["aria-haspopup"]: true;
    ["aria-expanded"]: boolean;
    onClick: () => void;
  };
  open: () => void;
  close: () => void;
};

const useDialogState = (isDefaultVisible = false): DialogState => {
  const [visible, setVisible] = useState(isDefaultVisible);
  const previousVisibility = useRef<boolean>();
  const ref = useRef<HTMLButtonElement | null>(null);

  useLayoutEffect(() => {
    if (
      previousVisibility.current &&
      !visible &&
      ref.current &&
      ref.current.focus
    ) {
      ref.current.focus();
    }
    previousVisibility.current = visible;
  }, [visible, ref.current]);

  const open = () => setVisible(true);
  const close = () => {
    setVisible(false);
  };

  return {
    close,
    open,
    toggle: {
      onClick: open,
      ref,
      /* eslint-disable no-useless-computed-key */
      ["aria-haspopup"]: true,
      ["aria-expanded"]: visible,
      /* eslint-enable no-useless-computed-key */
    },
    visible,
  };
};

export default useDialogState;
