import { css } from "styled-components";

const cropAttributes = {
  // don't change these as they are specifically for Inter UI and don't need
  // to change when the font size changes
  bottomAdjustment: 0,
  bottomCrop: 10,
  cropFontSize: 41,
  cropLineHeight: 1.2,
  topAdjustment: 0,
  topCrop: 9,
};

const getDyanmicTopCrop = (props: TextCropProps) =>
  Math.max(
    cropAttributes.topCrop +
      (props.lineHeight - cropAttributes.cropLineHeight) *
        (cropAttributes.cropFontSize / 2),
    0,
  ) / cropAttributes.cropFontSize;

const getDyamicBottomCrop = (props: TextCropProps) =>
  Math.max(
    cropAttributes.bottomCrop +
      (props.lineHeight - cropAttributes.cropLineHeight) *
        (cropAttributes.cropFontSize / 2),
    0,
  ) / cropAttributes.cropFontSize;

const Crop = css<TextCropProps>`
  &::before,
  &::after {
    content: "";
    display: block;
    height: 0;
    width: 0;
  }

  /* stylelint-disable function-calc-no-invalid */
  &::before {
    margin-bottom: calc(
      -${getDyanmicTopCrop}em + ${(props) => props.topAdjustment || cropAttributes.topAdjustment}px
    );
  }

  &::after {
    margin-top: calc(
      -${getDyamicBottomCrop}em + ${(props) => props.bottomAdjustment || cropAttributes.bottomAdjustment}px
    );
  }
  /* stylelint-enable function-calc-no-invalid */
`;
const TextCrop = css<TextCropProps>`
  max-width: ${(props) => props.theme.typography.lineLength};
  font-size: ${(props) => props.fontSize};
  line-height: ${(props) => props.lineHeight};
  ${(props) => (props.isUncropped ? "" : Crop)};
`;

export type TextCropProps = {
  lineHeight: number;
  fontSize: string;
  topAdjustment?: number;
  bottomAdjustment?: number;
  isUncropped: boolean;
};

export default TextCrop;
