import { Maybe } from "../../../__generated__/globalTypes";
import logger from "../../../utils/logger";

export type VisibleItem = {
  id: string;
  industryId: string;
  label: string;
  labelWithoutHighlights: string;
};

const mapIndustryOrSynonymToVisibleItem = (
  industryOrSynonym:
    | {
        __typename: "Industry";
        industryId: string;
        industryName: any;
        industryHighlightedName: Maybe<string>;
      }
    | {
        __typename: "IndustrySynonym";
        synonymName: string;
        synonymHighlightedName: Maybe<string>;
        synonymIndustry: { __typename: "Industry"; id: string; name: any };
      },
): VisibleItem | null => {
  switch (industryOrSynonym.__typename) {
    case "Industry": {
      return {
        id: `industry_${industryOrSynonym.industryId}`,
        industryId: industryOrSynonym.industryId,
        label:
          industryOrSynonym.industryHighlightedName ||
          industryOrSynonym.industryName,
        labelWithoutHighlights: industryOrSynonym.industryName,
      };
    }

    case "IndustrySynonym": {
      return {
        id: `synonym_${industryOrSynonym.synonymIndustry.id}_${industryOrSynonym.synonymName}`,
        industryId: industryOrSynonym.synonymIndustry.id,
        label: `${industryOrSynonym.synonymIndustry.name} (${
          industryOrSynonym.synonymHighlightedName ||
          industryOrSynonym.synonymName
        })`,
        labelWithoutHighlights: industryOrSynonym.synonymIndustry.name,
      };
    }

    default:
      logger({
        message: new Error(
          `Unsupported IndustryOrSynonym __typename: ${
            (industryOrSynonym as any).__typename
          }`,
        ),
        status: "error",
      });
      return null;
  }
};

export default mapIndustryOrSynonymToVisibleItem;
