import { documentToReactComponents } from "@contentful/rich-text-react-renderer";
import { BLOCKS, INLINES } from "@contentful/rich-text-types";
import transparentize from "polished/lib/color/transparentize";
import React, { useState } from "react";
import styled, { css } from "styled-components";

import Button from "@finanzchef24gmbh/design-system/src/Button";
import Card from "@finanzchef24gmbh/design-system/src/Card";
import BusinessLiabilityInsuranceIcon from "@finanzchef24gmbh/design-system/src/InsuranceIcons/BusinessLiabilityInsuranceIcon";
import CombinedShopContentsInsuranceIcon from "@finanzchef24gmbh/design-system/src/InsuranceIcons/CombinedShopContentsInsuranceIcon";
import LegalExpensesInsuranceIcon from "@finanzchef24gmbh/design-system/src/InsuranceIcons/LegalExpensesInsuranceIcon";
import { PassedProps } from "@finanzchef24gmbh/design-system/src/InsuranceIcons/useMappedProps";
import Spacings from "@finanzchef24gmbh/design-system/src/Spacings";
import { pxOrRem } from "@finanzchef24gmbh/design-system/src/Spacings/helpers";
import Text from "@finanzchef24gmbh/design-system/src/Text";
import { HighlightedIndustry } from "@finanzchef24gmbh/insurance-product-system/src/components/IndustrySearchDialog";
import { ConfigProps } from "../..";
import { AppProps } from "../../App";
import { buildAwinLink } from "../../utils";
import IndustrySearch from "./IndustrySearch";

const StyledHeading = styled(Text)`
  @media (max-width: ${(props) => props.theme.layout.mobileBreakpoint}) {
    margin-top: ${(props) =>
      pxOrRem({
        remValue: props.theme.spacings.medium,
        theme: props.theme,
        usePxUnits: true,
      })};
  }
`;

const StyledCTAContainer = styled.div`
  @media (max-width: ${(props) => props.theme.layout.mobileBreakpoint}) {
    margin-top: ${(props) =>
      pxOrRem({
        remValue: props.theme.spacings.medium,
        theme: props.theme,
        usePxUnits: true,
      })};
  }
`;

const StyledTabsInlineContainer = styled(Spacings.Inline)`
  > * + * {
    margin-left: ${(props) =>
      `calc(
        ${pxOrRem({
          remValue: props.theme.spacings.tiny,
          theme: props.theme,
          usePxUnits: true,
        })}
        / 2
      )`};
  }

  @media (max-width: ${(props) => props.theme.layout.mobileBreakpoint}) {
    display: none;
  }
`;

const StyledTab = styled.div<{ activeTab: boolean }>`
  background-color: ${(props) =>
    props.activeTab
      ? props.theme.palette.backgrounds.white
      : props.theme.palette.backgrounds.gray};
  border-radius: ${(props) => props.theme.borders.radius.small}px
    ${(props) => props.theme.borders.radius.small}px 0 0;
  box-shadow: ${(props) =>
    props.activeTab
      ? `-2px -2px 2px 0 ${transparentize(0.9, props.theme.palette.black)}`
      : `0 -4px 14px 0 ${transparentize(
          0.85,
          props.theme.palette.black,
        )} inset`};
  cursor: pointer;
`;

const StyledTabInsetSquish = styled(Spacings.InsetSquish)<{
  activeTab: boolean;
}>`
  ${(props) =>
    !props.activeTab &&
    css`
      padding-bottom: ${props.theme.insetSquish.spacings.small}px;
    `};
`;

const StyledCard = styled(Card)`
  @media (min-width: ${(props) => props.theme.layout.mobileBreakpoint}) {
    border-top-left-radius: 0;
  }
`;

const StyledCTATextStack = styled(Spacings.Stack)`
  flex: 4;
`;

const StylesInsuranceIconWrapper = styled.div`
  flex: 1;

  @media (max-width: ${(props) => props.theme.layout.mobileBreakpoint}) {
    display: none;
  }
`;

const StyledDesktoplabel = styled.label`
  display: none;

  @media (min-width: ${(props) => props.theme.layout.mobileBreakpoint}) {
    display: block;
  }
`;

const StyledMobilelabel = styled.label`
  @media (min-width: ${(props) => props.theme.layout.mobileBreakpoint}) {
    display: none;
  }
`;

const StyledCTAInputInset = styled(Spacings.Inset)`
  background-color: ${(props) => props.theme.palette.brand[100]};
  border-radius: 0 0 ${(props) => props.theme.borders.radius.medium}px
    ${(props) => props.theme.borders.radius.medium}px;
`;

const StyledCTAInputStack = styled(Spacings.Stack)`
  h5,
  p {
    margin: 0;
  }

  @media (max-width: ${(props) => props.theme.layout.mobileBreakpoint}) {
    button {
      margin-top: ${(props) =>
        pxOrRem({
          remValue: props.theme.spacings.small,
          theme: props.theme,
          usePxUnits: true,
        })} !important;
    }
  }

  @media (min-width: ${(props) => props.theme.layout.mobileBreakpoint}) {
    padding: ${(props) =>
        pxOrRem({
          remValue: props.theme.spacings.medium,
          theme: props.theme,
          usePxUnits: true,
        })}
      0;
  }
`;

const StyledIndustrySearchWrapper = styled.div`
  input {
    font-size: 18px !important;

    ::placeholder {
      color: rgba(0, 0, 0, 0.6) !important;
    }
  }

  button {
    height: 50px;
    display: flex;
    align-items: center;
    justify-content: center;

    svg {
      font-size: 18px;
    }
  }

  ul {
    li {
      margin: 0 !important;
      padding: ${(props) => props.theme.insetSquish.spacings.small}px
        ${(props) =>
          props.theme.insetSquish.spacings.small *
          props.theme.insetSquish.squishRatio}px !important;

      p,
      span {
        font-size: 16px;
        font-family: "Inter UI", "Helvetica Neue", Helvetica, Arial, sans-serif !important;
      }
    }
  }
`;

const StyledQuickIndustrySelectionsInlineUl = styled(Spacings.Inline)`
  margin-top: ${(props) =>
    pxOrRem({
      remValue: props.theme.spacings.medium,
      theme: props.theme,
      usePxUnits: true,
    })} !important;

  @media (max-width: ${(props) => props.theme.layout.mobileBreakpoint}) {
    display: none;
  }
`;

const StyledListItem = styled.li`
  flex: 1;
  margin: 0 !important;

  :not(:first-of-type) {
    margin-left: ${(props) =>
      pxOrRem({
        remValue: props.theme.spacings.small,
        theme: props.theme,
        usePxUnits: true,
      })} !important;
  }
`;

const QuickIndustrySelectionLink = styled.a`
  display: block;
`;

const QuickIndustrySelectionButton = styled(Button)`
  height: 100%;
  width: 100%;
`;

const StyledButtonText = styled(Text)`
  color: ${(props) => props.theme.palette.brand[800]};
`;

type UnknownIndustryProps = {
  content: AppProps["homepageContentfulData"];
  awinAffId: ConfigProps["config"]["awinAffId"];
  partnerName: ConfigProps["config"]["partnerName"];
  customerFrontendOrigin: AppProps["customerFrontendOrigin"];
  homepageOrigin: AppProps["homepageOrigin"];
};

const UnknownIndustry: React.FC<UnknownIndustryProps> = ({
  awinAffId,
  content,
  customerFrontendOrigin,
  homepageOrigin,
  partnerName,
}) => {
  const [activeTab, setActiveTab] = useState(0);

  return content ? (
    <Spacings.Stack scale="huge" usePxUnits>
      <StyledHeading
        textStyle="headline4"
        as="span"
        isOnDarkBackground
        usePxFontSize
      >
        {content.heading}
      </StyledHeading>

      <StyledCTAContainer>
        <StyledTabsInlineContainer
          scale="tiny"
          alignItems="flex-end"
          usePxUnits
        >
          {content.tabs?.map((tab, labelIndex) => {
            if (!tab) {
              return null;
            }

            return (
              <StyledTab
                key={labelIndex}
                activeTab={labelIndex === activeTab}
                onClick={() => setActiveTab(labelIndex)}
              >
                <StyledTabInsetSquish
                  activeTab={labelIndex === activeTab}
                  scale="medium"
                >
                  <Text textStyle="headline6" as="span" usePxFontSize>
                    {tab.tabLabel}
                  </Text>
                </StyledTabInsetSquish>
              </StyledTab>
            );
          })}
        </StyledTabsInlineContainer>

        {content.tabs?.map((tab, tabIndex) => {
          if (!tab) {
            return null;
          }

          const iconComponents: {
            [iconName: string]: React.ComponentType<PassedProps>;
          } = {
            BusinessLiabilityInsuranceIcon,
            CombinedShopContentsInsuranceIcon,
            LegalExpensesInsuranceIcon,
          };
          const InsuranceIcon = tab.icon ? iconComponents[tab.icon] : undefined;

          return (
            tabIndex === activeTab && (
              <StyledCard key={tabIndex}>
                <Spacings.Inset scale="medium" usePxUnits>
                  <Spacings.Inline
                    scale="massive"
                    alignItems="center"
                    usePxUnits
                  >
                    <StyledCTATextStack scale="medium" usePxUnits>
                      <div>
                        <StyledDesktoplabel htmlFor="fc24-ac-input">
                          <Text
                            textStyle="headline5"
                            as="span"
                            dangerouslySetInnerHTML={{
                              __html: tab.ctaSectionTitle || "",
                            }}
                            usePxFontSize
                          />
                        </StyledDesktoplabel>
                        <StyledMobilelabel htmlFor="fc24-ac-input-dialog">
                          <Text
                            textStyle="headline5"
                            as="span"
                            dangerouslySetInnerHTML={{
                              __html: tab.ctaSectionTitle || "",
                            }}
                            usePxFontSize
                          />
                        </StyledMobilelabel>
                      </div>

                      {tab.ctaSectionText?.json ? (
                        documentToReactComponents(tab.ctaSectionText.json, {
                          renderNode: {
                            [BLOCKS.PARAGRAPH]: (_NODE, children: any) => (
                              <Text textStyle="caption" usePxFontSize>
                                {children}
                              </Text>
                            ),
                            [INLINES.HYPERLINK]: (node, children: any) => {
                              const link = buildAwinLink({
                                awinAffId,
                                link: node.data.uri || "",
                                partnerName,
                              });

                              return (
                                // eslint-disable-next-line react/jsx-no-target-blank
                                <a
                                  href={link}
                                  rel="nofollow noopener"
                                  target="_blank"
                                >
                                  {children[0]}
                                </a>
                              );
                            },
                          },
                        })
                      ) : (
                        <Text
                          textStyle="caption"
                          dangerouslySetInnerHTML={{
                            __html: tab.ctaSectionText as string,
                          }}
                          usePxFontSize
                        />
                      )}
                    </StyledCTATextStack>

                    {InsuranceIcon ? (
                      <StylesInsuranceIconWrapper>
                        <InsuranceIcon />
                      </StylesInsuranceIconWrapper>
                    ) : null}
                  </Spacings.Inline>
                </Spacings.Inset>

                <StyledCTAInputInset scale="medium" usePxUnits>
                  <StyledCTAInputStack scale="medium" usePxUnits>
                    <StyledIndustrySearchWrapper>
                      <IndustrySearch
                        awinAffId={awinAffId}
                        partnerName={partnerName}
                        placeholder={undefined}
                        buttonText={tab.ctaButtonText || undefined}
                        buttonTextMobile={tab.ctaButtonText || undefined}
                        selectedProductTypeCombination={
                          tab.industrySelectionProductCombinationId || undefined
                        }
                        highlightedIndustries={
                          tab.quickIndustrySelections
                            ? tab.quickIndustrySelections.reduce<
                                HighlightedIndustry[]
                              >((acc, industrySelection) => {
                                if (
                                  industrySelection &&
                                  industrySelection.industryId &&
                                  industrySelection.title
                                ) {
                                  acc.push({
                                    id: industrySelection.industryId,
                                    name: industrySelection.title,
                                  });
                                }
                                return acc;
                              }, [])
                            : undefined
                        }
                        customerFrontendOrigin={customerFrontendOrigin}
                        homepageOrigin={homepageOrigin}
                      />
                    </StyledIndustrySearchWrapper>

                    <StyledQuickIndustrySelectionsInlineUl as="ul" usePxUnits>
                      {tab.quickIndustrySelections?.map(
                        (quickIndustrySelection) => {
                          if (!quickIndustrySelection) {
                            return null;
                          }

                          // TODO: remove the `agent` query parameter once it is no longer required
                          let entryRouterLink = `${
                            process.env.ORIGIN || homepageOrigin
                          }/backend/0.3/entry-router?agent=fc24&tenant=fc24&target=interview&industryId=${
                            quickIndustrySelection.industryId
                          }`;

                          if (quickIndustrySelection.productTypeCombinationId) {
                            entryRouterLink += `&selectedProductTypeCombination=${quickIndustrySelection.productTypeCombinationId}`;
                          }

                          const quickLink = buildAwinLink({
                            awinAffId,
                            link: entryRouterLink,
                            partnerName,
                          });

                          return (
                            <StyledListItem key={quickIndustrySelection.title}>
                              <QuickIndustrySelectionLink
                                href={quickLink}
                                target="_blank"
                                rel="nofollow noopener"
                              >
                                <QuickIndustrySelectionButton
                                  scale="medium"
                                  buttonType="flat"
                                  tabIndex={-1}
                                >
                                  <StyledButtonText
                                    textStyle="caption"
                                    as="span"
                                    usePxFontSize
                                  >
                                    {quickIndustrySelection.title}
                                  </StyledButtonText>
                                </QuickIndustrySelectionButton>
                              </QuickIndustrySelectionLink>
                            </StyledListItem>
                          );
                        },
                      )}
                    </StyledQuickIndustrySelectionsInlineUl>
                  </StyledCTAInputStack>
                </StyledCTAInputInset>
              </StyledCard>
            )
          );
        })}
      </StyledCTAContainer>
    </Spacings.Stack>
  ) : null;
};

export default UnknownIndustry;
