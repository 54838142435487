import gql from "graphql-tag";

const QUERY = gql`
  query IndustryQuery($query: String!) {
    searchIndustries(query: $query, limit: 20) {
      industriesAndSynonyms {
        __typename

        ... on Industry {
          industryId: id
          industryName: name
          industryHighlightedName: highlightedName
        }

        ... on IndustrySynonym {
          synonymName: name
          synonymHighlightedName: highlightedName
          synonymIndustry: industry {
            id
            name
          }
        }
      }
    }
  }
`;

export default QUERY!.loc!.source.body;
