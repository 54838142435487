import React, { useEffect, useState } from "react";
import styled from "styled-components";

import Spacings from "@finanzchef24gmbh/design-system/src/Spacings";
import { BlockWidgetProps } from "@finanzchef24gmbh/homepage/src/components/blocks/BlockWidget";
import { ConfigProps } from ".";
import Footer from "./components/Footer";
import Header from "./components/Header";
import UnknownIndustry from "./components/UnknownIndustry";
import useContentfulData from "./hooks/useContentfulData";
import useEkomiRating, { EkomiProps } from "./hooks/useEkomiRating";
import { isTouchDevice } from "./utils/touchDevice";

const StyledGradientInsetSquish = styled(Spacings.InsetSquish)`
  box-sizing: border-box;
  background: linear-gradient(
    to bottom right,
    ${(props) => props.theme.palette.brand[400]} 0%,
    ${(props) => props.theme.palette.brand[500]} 50%,
    ${(props) => props.theme.palette.brand[600]} 100%
  );

  @media (min-width: ${(props) => props.theme.layout.mobileBreakpoint}) {
    max-width: 660px;
  }
`;

export type AppProps = {
  awinAffId?: ConfigProps["config"]["awinAffId"];
  partnerName?: ConfigProps["config"]["partnerName"];
  contentfulWidgetId?: ConfigProps["config"]["contentfulWidgetId"];
  homepageContentfulData?: BlockWidgetProps["contentBlock"] & {
    empty?: boolean;
  };
  homepageEkomi?: EkomiProps;
  homepageOrigin?: string;
  customerFrontendOrigin?: string;
};

const App: React.FC<AppProps> = ({
  awinAffId,
  contentfulWidgetId,
  customerFrontendOrigin,
  homepageContentfulData,
  homepageEkomi,
  homepageOrigin,
  partnerName,
}) => {
  const contentfulData =
    homepageContentfulData ||
    useContentfulData({
      awinAffId,
      contentfulWidgetId,
      partnerName,
    });

  const ekomi = homepageEkomi || useEkomiRating();

  const [hoverClass, setHoverClass] = useState("");

  useEffect(() => {
    if (!isTouchDevice()) {
      setHoverClass("device-can-hover");
    }
  }, []);

  return !contentfulData?.empty ? (
    <StyledGradientInsetSquish className={hoverClass} scale="big">
      <Spacings.Stack usePxUnits>
        <Header
          awinAffId={awinAffId}
          partnerName={partnerName}
          ekomi={ekomi}
          homepageOrigin={homepageOrigin}
        />
        <Spacings.Stack scale="big" usePxUnits>
          <UnknownIndustry
            awinAffId={awinAffId}
            partnerName={partnerName}
            content={contentfulData}
            customerFrontendOrigin={customerFrontendOrigin}
            homepageOrigin={homepageOrigin}
          />
          <Footer
            awinAffId={awinAffId}
            partnerName={partnerName}
            ekomi={ekomi}
            content={contentfulData}
          />
        </Spacings.Stack>
      </Spacings.Stack>
    </StyledGradientInsetSquish>
  ) : null;
};

export default App;
