import React from "react";
import styled, { ThemeProvider } from "styled-components";

import designSystemTheme from "@finanzchef24gmbh/design-system/src/designTokens";
import Rating, {
  RatingProps,
} from "@finanzchef24gmbh/design-system/src/Rating";
import { createHomepageTheme } from "@finanzchef24gmbh/homepage/src/createHomePageTheme";

const StyledRatingWrapper = styled.div`
  > * {
    font-size: 16px;
    display: block;
  }
`;

const CustomRating: React.FC<RatingProps> = (props) => {
  const homepageTheme = createHomepageTheme(designSystemTheme, 1100);

  const customTheme = {
    ...homepageTheme,
    palette: {
      ...homepageTheme.palette,
      accent: {
        ...homepageTheme.palette.accent,
        // Use a different color for the rating icons.
        light: homepageTheme.palette.white,
      },
    },
  };

  return (
    <ThemeProvider theme={customTheme}>
      <StyledRatingWrapper>
        <Rating {...props} />
      </StyledRatingWrapper>
    </ThemeProvider>
  );
};

export default CustomRating;
