import transparentize from "polished/lib/color/transparentize";
import React, { SyntheticEvent } from "react";
import styled from "styled-components";

const IconButton = React.forwardRef<HTMLButtonElement, IconButtonProps>(
  ({ icon, label, onClick, ...props }, ref) => (
    <StyledIconButton
      aria-label={label}
      ref={ref}
      onClick={onClick}
      type="button"
      {...props}
    >
      {icon}
    </StyledIconButton>
  ),
);

const StyledIconButton = styled.button`
  background: none;
  cursor: pointer;
  outline: none;
  display: flex;

  /* needs 1px padding, otherwise the svg inside the button will be cut off
  on one or more sides in safari 🙄 */
  padding: 1px;
  border: none;
  &:focus {
    box-shadow: 0 0 0 3px
      ${(props) => transparentize(0.7, props.theme.palette.brand.main)};
  }
`;

type IconButtonProps = {
  icon: React.ReactNode;
  onClick: (event: SyntheticEvent<HTMLButtonElement>) => void;
  label: string;
};

export default IconButton;
