/* eslint-disable react/jsx-no-target-blank */
import React from "react";
import styled from "styled-components";

import Spacings from "@finanzchef24gmbh/design-system/src/Spacings";
import { ConfigProps } from "..";
import { AppProps } from "../App";
import { EkomiProps } from "../hooks/useEkomiRating";
import { buildAwinLink } from "../utils";

const StyledSpacingsInline = styled(Spacings.Inline)`
  justify-content: flex-end;
`;

const StyledEkomiLink = styled.a`
  @media (max-width: ${(props) => props.theme.layout.mobileBreakpoint}) {
    display: none;
  }
`;

type HeaderProps = {
  homepageOrigin?: AppProps["homepageOrigin"];
  ekomi: EkomiProps;
  awinAffId: ConfigProps["config"]["awinAffId"];
  partnerName: ConfigProps["config"]["partnerName"];
};

const Header: React.FC<HeaderProps> = ({
  awinAffId,
  ekomi,
  homepageOrigin,
  partnerName,
}) => {
  const finanzchefLogoLink = buildAwinLink({
    awinAffId,
    link: process.env.ORIGIN || homepageOrigin || "",
    partnerName,
  });

  return (
    <StyledSpacingsInline alignItems="center" usePxUnits>
      <StyledEkomiLink
        href={ekomi.link}
        target="_blank"
        rel="nofollow noopener"
      >
        {ekomi.imgSrc && (
          <img
            src={ekomi.imgSrc}
            alt={`eKomi Siegel ${ekomi.seal} für Finanzchef24`}
          />
        )}
      </StyledEkomiLink>
      <a href={finanzchefLogoLink} target="_blank" rel="nofollow noopener">
        <img
          src="https://images.ctfassets.net/ta73me28xrs3/32pWfzpLB06DEjpIqrmj7p/2298cc39d4c6a2b3a50c296b76fee6e4/brand-logo-white.svg"
          width="88"
          height="33"
          alt="Finanzchef24"
        />
      </a>
    </StyledSpacingsInline>
  );
};

export default Header;
