export type Scale = "small" | "medium" | "big" | "huge" | "gigantic";

export type PassedProps = {
  scale?: Scale;
};

export type MappedProps = {
  height?: number;
  width?: number;
};

const getSize = (scale?: Scale) => {
  switch (scale) {
    case "small":
      return 32;
    case "medium":
      return 64;
    case "huge":
      return 128;
    case "gigantic":
      return 180;
    case "big":
    default:
      return 96;
  }
};

export const useMappedProps = ({
  scale,
  ...rest
}: PassedProps): MappedProps => ({
  ...rest,
  height: getSize(scale),
  width: getSize(scale),
});
