type LoggerProps = {
  status?: "warn" | "error";
  message: any;
};

const logger = ({ status, message }: LoggerProps) => {
  switch (status) {
    case "warn":
      // eslint-disable-next-line no-console
      console.warn("%c Warning:", "background: orange; color: white;", message);
      break;
    case "error":
      // eslint-disable-next-line no-console
      console.error("%c Error:", "background: red; color: white;", message);
      break;
    default:
      // eslint-disable-next-line no-console
      console.log(message);
      break;
  }
};

export default logger;
