export const buildAwinLink = ({
  awinAffId,
  partnerName,
  link,
}: {
  awinAffId?: string;
  partnerName?: string;
  link: string;
}) => {
  const hasParams = link.indexOf("?") !== -1;

  const utmParams = `${hasParams ? "&" : "?"}utm_source=${
    partnerName || ""
  }&utm_medium=affiliate&utm_campaign=widget`;

  if (awinAffId) {
    const encodedLink = encodeURIComponent(link + utmParams);
    const awinLink = `https://www.awin1.com/cread.php?awinmid=15345&awinaffid=${awinAffId}&ued=${encodedLink}`;

    return awinLink || "";
  }

  return link + utmParams;
};

export const isServerSide = () => typeof window === "undefined";
