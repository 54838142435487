import React from "react";

type GraphqlErrorOptions = {
  graphQLErrors?: any[];
  errorMessage?: string;
};

// Simulates Apollo GraphqlError.
class GraphqlError extends Error {
  graphQLErrors: any[] | undefined;

  constructor({ graphQLErrors, errorMessage }: GraphqlErrorOptions) {
    super(errorMessage);

    this.graphQLErrors = graphQLErrors;
  }
}

type UseQueryOptions<TVariables> = {
  variables: TVariables;
};

// Simulates Apollo useQuery.
const useQuery = <TData = any, TVariables = any>(
  url: string,
  query: string,
  options: UseQueryOptions<TVariables>,
) => {
  const [loading, setLoading] = React.useState(false);
  const [error, setError] = React.useState<any | undefined>();
  const [data, setData] = React.useState<TData | undefined>();

  React.useEffect(() => {
    fetchData(options.variables);
  }, []);

  const fetchData = async (variables: TVariables) => {
    setLoading(true);

    try {
      const response = await fetch(url, {
        body: JSON.stringify({
          query,
          variables: variables || {},
        }),
        credentials: "include",
        headers: {
          "Content-Type": "application/json",
        },
        method: "POST",
      });

      const responseBody = await response.json();

      setData(responseBody.data || undefined);
      setError(
        responseBody.errors
          ? new GraphqlError({ graphQLErrors: responseBody.errors })
          : undefined,
      );
      setLoading(false);
    } catch (err) {
      setData(undefined);
      setError(new GraphqlError({ errorMessage: err.message }));
      setLoading(false);
    }
  };

  const refetch = (variables: TVariables) => {
    fetchData(variables);
  };

  return {
    data,
    error,
    loading,
    refetch,
  };
};

export default useQuery;
