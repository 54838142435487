import styled, { css } from "styled-components";

type CenterProps = {
  intrinsic?: boolean;
  max?: string;
  andText?: boolean;
};

const Center = styled.div.attrs<CenterProps>((props) => ({
  max: props.max || props.theme.typography.lineLength,
}))<CenterProps>`
  box-sizing: content-box;
  margin-left: auto;
  margin-right: auto;
  max-width: ${(props) => props.max};
  ${(props) =>
    props.intrinsic &&
    css`
      display: flex;
      flex-direction: column;
      align-items: center;
    `}
  ${(props) =>
    props.andText &&
    css`
      text-align: center;
    `}
`;

export default Center;
