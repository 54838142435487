import FocusTrap from "focus-trap-react";
import React, { useEffect, useRef } from "react";
import { createPortal } from "react-dom";
import styled, { css } from "styled-components";

import { DialogLayout } from "../DialogLayout";
import { pxOrRem } from "../Spacings/helpers";

export const DialogContainer = styled.aside<{
  position?: number;
  isFullScreen?: boolean;
  usePxUnits?: boolean;
}>`
  display: flex;
  justify-content: center;
  top: 0;
  left: 0;
  padding: ${(props) =>
    props.isFullScreen
      ? 0
      : pxOrRem({
          remValue: props.theme.spacings.small,
          theme: props.theme,
          usePxUnits: props.usePxUnits,
        })};
  z-index: ${(props) => props.theme.zIndexes.dialog};
  background-color: ${(props) => props.theme.palette.backgrounds.transparent};

  /*
   * Fixes a Safari rendering intersection bug that causes an image with transform: rotateY to
   * partially overlap the dialog.
   * Example page: https://www.finanzchef24.de/versicherung/architekt
   * Solution: https://stackoverflow.com/a/18167565/5133172
   */
  transform: translateZ(100vw);

  ${(props) =>
    typeof props.position === "number"
      ? css`
          position: absolute;
          height: calc(
            ${document.body.scrollHeight - props.position}px -
              ${pxOrRem({
                remValue: props.theme.spacings.small,
                theme: props.theme,
                usePxUnits: props.usePxUnits,
              })}
          );
          width: calc(
            100% - 2 *
              ${pxOrRem({
                remValue: props.theme.spacings.small,
                theme: props.theme,
                usePxUnits: props.usePxUnits,
              })}
          );
          align-items: flex-start;
          padding-top: ${props.position}px;
        `
      : css`
          align-items: center;
          position: fixed;
          right: 0;
          bottom: 0;
          overflow-y: auto;
        `}
`;

type DialogProps = {
  visible: boolean;
  content: React.ReactNode;
  toggleRef: React.MutableRefObject<HTMLButtonElement | null>;
  onClose: () => void;
  children?: React.ReactNode;
  footer?: React.ReactNode;
  header?: React.ReactNode;
  closeLabel: string;
  className?: string;
  position?: "centered" | "relative";
  isFullScreen?: boolean;
  isClosable?: boolean;
  usePxUnits?: boolean;
  focusTrapOptions?: {
    initialFocus: string;
  };
};

const ESC_KEY_CODE = 27;

const Dialog: React.FC<React.PropsWithChildren<DialogProps>> = ({
  onClose,
  closeLabel,
  content,
  footer,
  header,
  visible,
  className,
  toggleRef,
  position = "centered",
  isFullScreen,
  isClosable,
  usePxUnits,
  focusTrapOptions,
}) => {
  const dialogRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (position === "relative") {
      return;
    }
    if (visible) {
      document.body.classList.add("disable-overflow");
    } else {
      document.body.classList.remove("disable-overflow");
    }
  }, [visible, position]);

  if (!visible) {
    return null;
  }

  return createPortal(
    <FocusTrap focusTrapOptions={focusTrapOptions}>
      <DialogContainer
        className={`${className}-container`}
        position={
          position === "relative" && toggleRef.current
            ? toggleRef.current.getBoundingClientRect().top +
              // IE11 uses `pageYOffset` instead of `scrollY` 💩
              (window.scrollY || window.pageYOffset)
            : undefined
        }
        isFullScreen={isFullScreen}
        onClick={(event) => {
          if (
            dialogRef.current &&
            !dialogRef.current.contains(event.target as HTMLElement)
          ) {
            onClose();
          }
        }}
        onKeyDown={(event: React.KeyboardEvent) => {
          if (event.keyCode === ESC_KEY_CODE) {
            onClose();
          }
        }}
        role="dialog"
        aria-modal="true"
        aria-labelledby="dialog-label"
        tabIndex={-1}
        usePxUnits={usePxUnits}
      >
        <DialogLayout
          isFullScreen={isFullScreen}
          className={className}
          footer={footer}
          header={header}
          onClose={onClose}
          closeLabel={closeLabel}
          setDialogRef={dialogRef}
          dialogLabelKey="dialog-label"
          isClosable={isClosable}
          usePxUnits={usePxUnits}
        >
          {content}
        </DialogLayout>
      </DialogContainer>
    </FocusTrap>,
    document.body,
  );
};

export default Dialog;
