import React from "react";
import styled, { css } from "styled-components";

import AutocompleteInput from "@finanzchef24gmbh/design-system/src/AutocompleteInput";
import Button from "@finanzchef24gmbh/design-system/src/Button";
import useDialogState from "@finanzchef24gmbh/design-system/src/Dialog/useDialogState";
import ErrorSummary from "@finanzchef24gmbh/design-system/src/ErrorSummary";
import FormInput from "@finanzchef24gmbh/design-system/src/FormInput";
import ChevronRightIcon from "@finanzchef24gmbh/design-system/src/Icons/ChevronRightIcon";
import InputBox from "@finanzchef24gmbh/design-system/src/InputBox";
import Markdown from "@finanzchef24gmbh/design-system/src/Markdown";
import Spacings from "@finanzchef24gmbh/design-system/src/Spacings";
import Text from "@finanzchef24gmbh/design-system/src/Text";
import useIndustrySearch, {
  UseIndustrySearchArgs,
} from "../../hooks/useIndustrySearch";
import { VisibleItem } from "../../hooks/useIndustrySearch/mapIndustryOrSynonymToVisibleItem";
import HighlightedIndustryButton from "../HighlightedIndustryButton";
import IndustrySearchDialog, {
  HighlightedIndustry,
  HORIZONTAL_PADDING,
  InlineInputAndButton,
  VERTICAL_PADDING,
} from "../IndustrySearchDialog";

type ExtendedVisibleItem = VisibleItem & {
  isHighlighted?: boolean;
};

const StyledHighlightedIndustryButton = styled(HighlightedIndustryButton)<{
  isHighlighted: boolean;
}>`
  ${(props) =>
    props.isHighlighted &&
    css`
      background: ${props.theme.palette.brand[300]};
      border-color: ${props.theme.palette.brand[300]};
    `}
`;

const MobileOnly = styled.div`
  @media (min-width: ${(props) => props.theme.layout.mobileBreakpoint}) {
    display: none;
  }
`;

const TabletAndDeskopOnly = styled.div`
  display: none;
  @media (min-width: ${(props) => props.theme.layout.mobileBreakpoint}) {
    display: block;
  }
`;

const ButtonContentWrapper = styled(Spacings.Inline)`
  /**
   * Switching this element to inline ensures its parent div line-height
   * is respected so the button shows as the correct scale "big"
  */
  display: inline-flex;
  justify-content: center;
`;

const FormInputPadding = css`
  padding: ${VERTICAL_PADDING}px ${HORIZONTAL_PADDING}px;
`;

const StyledAutocompleteWrapper = styled.div`
  flex-grow: 1;

  /**
   * The input can't have the same padding as the button
   * because the text on the button is cropped so the padding of the input
   * should be less than the padding of the button.
   * There is also a difference of 1px or 2px because of the text cropping.
   * We fix this by doing the same calculation as in the TextCrop component.
  */

  ${InputBox} input {
    ${FormInputPadding}
  }
`;

const StyledFormInputWithPadding = styled(FormInput)`
  ${FormInputPadding}
`;

type Messages = React.ComponentProps<
  typeof IndustrySearchDialog
>["messages"] & {
  loading: string;
  button: string;
  buttonMobile?: string;
  highlightedIndustriesTitle?: string;
};

export type EntryRouterResult = {
  application: "customer-frontend";
  path: string;
  maskedPath?: string;
};

type IndustrySearchProps = Omit<UseIndustrySearchArgs, "messages"> & {
  className?: string;
  placeholder: string;
  messages: Messages;
  desktopId: string;
  mobileId: string;
  highlightedIndustries?: HighlightedIndustry[];
  showHighlightedIndustriesInTheAutocomplete?: boolean;
  onHighlightedIndustrySelection?: (
    highlightedIndustry: HighlightedIndustry,
  ) => void;
  usePxUnits?: boolean;
};

const IndustrySearch: React.FC<
  React.PropsWithChildren<IndustrySearchProps>
> = ({
  backendUrl,
  className,
  tenant,
  placeholder,
  selectedProductTypeIds,
  selectedProductTypeCombination,
  messages,
  mobileId,
  desktopId,
  allowSubmitWithoutSelection,
  highlightedIndustries,
  showHighlightedIndustriesInTheAutocomplete,
  onHighlightedIndustrySelection,
  onNavigation,
  onIndustrySelection,
  usePxUnits,
}) => {
  const dialogState = useDialogState();
  const {
    errorMessage,
    getVisibleItems,
    handleInputValueChange,
    handleItemSelection,
    handleSubmit,
    hasError,
    inputValue,
    loading,
  } = useIndustrySearch({
    allowSubmitWithoutSelection,
    backendUrl,
    messages: {
      errorMessageEmptyInput: messages.errorMessageEmptyInput,
      errorMessageEntryRouter: messages.errorMessageEntryRouter,
      notFound: messages.notFound,
    },
    onIndustrySelection,
    onNavigation,
    selectedProductTypeCombination,
    selectedProductTypeIds,
    tenant,
  });

  const visibleItems = getVisibleItems();
  const shouldRenderHighlightedIndustriesInTheAutocomplete =
    showHighlightedIndustriesInTheAutocomplete &&
    visibleItems.length === 0 &&
    highlightedIndustries &&
    highlightedIndustries.length > 0;

  return (
    <div>
      <MobileOnly>
        <Spacings.Stack usePxUnits={usePxUnits}>
          <StyledAutocompleteWrapper>
            <StyledFormInputWithPadding
              id={mobileId}
              aria-labelledby={mobileId}
              placeholder={placeholder}
              onFocus={() => {
                dialogState.open();
              }}
            />
          </StyledAutocompleteWrapper>

          <Button buttonType="primary" scale="big" {...dialogState.toggle}>
            <ButtonContentWrapper
              scale="tiny"
              alignItems="center"
              usePxUnits={usePxUnits}
            >
              <Text
                as="span"
                textStyle="bodyProlonged"
                usePxFontSize={usePxUnits}
                isOnDarkBackground
              >
                {messages.buttonMobile || messages.button}
              </Text>
              <ChevronRightIcon isOnDarkBackground />
            </ButtonContentWrapper>
          </Button>

          <IndustrySearchDialog
            allowSubmitWithoutSelection={allowSubmitWithoutSelection}
            backendUrl={backendUrl}
            className={className}
            dialogState={dialogState}
            highlightedIndustries={highlightedIndustries}
            onHighlightedIndustrySelection={onHighlightedIndustrySelection}
            messages={messages}
            onNavigation={onNavigation}
            onIndustrySelection={onIndustrySelection}
            placeholder={placeholder}
            selectedProductTypeCombination={selectedProductTypeCombination}
            selectedProductTypeIds={selectedProductTypeIds}
            tenant={tenant}
            usePxUnits={usePxUnits}
          />
        </Spacings.Stack>
      </MobileOnly>
      <TabletAndDeskopOnly>
        <form onSubmit={handleSubmit}>
          <Spacings.Stack scale="medium" usePxUnits={usePxUnits}>
            <Spacings.Stack scale="medium" usePxUnits={usePxUnits}>
              <InlineInputAndButton>
                <StyledAutocompleteWrapper>
                  <AutocompleteInput.SingleSelect<ExtendedVisibleItem>
                    id={desktopId}
                    labelId={`${desktopId}-label`}
                    inputValue={inputValue}
                    isLoading={loading}
                    invalid={hasError}
                    loadingText={
                      <Text
                        priority="secondary"
                        isUncropped
                        usePxFontSize={usePxUnits}
                      >
                        {messages.loading}
                      </Text>
                    }
                    onInputValueChange={handleInputValueChange}
                    onSelect={handleItemSelection}
                    placeholder={placeholder}
                    renderItem={(item, { isActive, isSelected }) => {
                      if (item.isHighlighted) {
                        return (
                          <StyledHighlightedIndustryButton
                            buttonType="flat"
                            scale="small"
                            name={item.label}
                            isHighlighted={isActive || isSelected}
                            onClick={() => {
                              if (onHighlightedIndustrySelection) {
                                onHighlightedIndustrySelection({
                                  id: item.id,
                                  name: item.label,
                                });
                              }
                            }}
                          />
                        );
                      }

                      return <Markdown>{item.label}</Markdown>;
                    }}
                    selectedItem={null}
                    menuTitle={
                      shouldRenderHighlightedIndustriesInTheAutocomplete
                        ? messages.highlightedIndustriesTitle
                        : undefined
                    }
                    visibleItems={() => {
                      if (shouldRenderHighlightedIndustriesInTheAutocomplete) {
                        return highlightedIndustries!.map(
                          (highlightedIndustry) => ({
                            id: highlightedIndustry.id,
                            industryId: highlightedIndustry.id,
                            isHighlighted: true,
                            label: highlightedIndustry.name,
                            labelWithoutHighlights: highlightedIndustry.name,
                          }),
                        );
                      }

                      return visibleItems;
                    }}
                    usePxUnits={usePxUnits}
                  />
                </StyledAutocompleteWrapper>

                <Button type="submit" buttonType="primary" scale="big">
                  <ButtonContentWrapper
                    scale="tiny"
                    alignItems="center"
                    usePxUnits={usePxUnits}
                  >
                    <Text
                      as="span"
                      textStyle="bodyProlonged"
                      usePxFontSize={usePxUnits}
                      isOnDarkBackground
                    >
                      {messages.button}
                    </Text>
                    <ChevronRightIcon isOnDarkBackground />
                  </ButtonContentWrapper>
                </Button>
              </InlineInputAndButton>
              {hasError && (
                <ErrorSummary
                  title={messages.errorTitle}
                  usePxUnits={usePxUnits}
                >
                  <Text priority="error" usePxFontSize={usePxUnits}>
                    {errorMessage || messages.errorMessageQuery}
                  </Text>
                </ErrorSummary>
              )}
            </Spacings.Stack>
          </Spacings.Stack>
        </form>
      </TabletAndDeskopOnly>
    </div>
  );
};

export default IndustrySearch;
