import styled from "styled-components";

import { mapScaleToPixels, SpacingScales } from "../Spacings/helpers";

export type StackProps = {
  alignItems?: string;
  scale?: SpacingScales;
  usePxUnits?: boolean;
};

const Stack = styled.div<StackProps>`
  display: flex;
  flex-direction: column;
  align-items: ${(props) => props.alignItems};
  > * + * {
    margin: ${(props) =>
        mapScaleToPixels(props.scale!, props.theme, props.usePxUnits)}
      0 0 0;
  }
`;

Stack.defaultProps = {
  alignItems: "stretch",
  scale: "small",
};

export default Stack;
