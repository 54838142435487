import { Theme } from "../designTokens";

export type SpacingScales =
  | "tiny"
  | "small"
  | "medium"
  | "big"
  | "huge"
  | "massive"
  | "gigantic"
  | "astronomic";

type PxOrRem = {
  usePxUnits: boolean | undefined;
  remValue: string;
  theme: Theme;
};

export const pxOrRem = ({ usePxUnits, remValue, theme }: PxOrRem) =>
  usePxUnits
    ? `${parseFloat(remValue) * theme.typography.baseFontSize}px`
    : remValue;

export const mapScaleToPixels = (
  scale: SpacingScales,
  theme: Theme,
  usePxUnits: boolean | undefined = false,
) => {
  switch (scale) {
    case "tiny":
      return pxOrRem({ usePxUnits, remValue: theme.spacings.tiny, theme });
    case "small":
      return pxOrRem({ usePxUnits, remValue: theme.spacings.small, theme });
    case "medium":
      return pxOrRem({ usePxUnits, remValue: theme.spacings.medium, theme });
    case "big":
      return pxOrRem({ usePxUnits, remValue: theme.spacings.big, theme });
    case "huge":
      return pxOrRem({ usePxUnits, remValue: theme.spacings.huge, theme });
    case "massive":
      return pxOrRem({ usePxUnits, remValue: theme.spacings.massive, theme });
    case "gigantic":
      return pxOrRem({ usePxUnits, remValue: theme.spacings.gigantic, theme });
    case "astronomic":
      return pxOrRem({
        remValue: theme.spacings.astronomic,
        theme,
        usePxUnits,
      });
    default:
      return 0;
  }
};
