import React, { Ref } from "react";
import styled, { css } from "styled-components";

import Divider from "../Divider";
import IconButton from "../IconButton";
import CloseIcon from "../Icons/CloseIcon";
import Layer from "../Layer";
import ListItem from "../ListItem";
import ResponsiveHelper from "../ResponsiveHelper";
import Spacings from "../Spacings";

export const DialogBox = styled(Layer.Overlay)<{ isFullScreen?: boolean }>`
  overflow: auto;
  width: 100%;
  max-height: 100%;
  border-radius: ${(props) =>
    props.isFullScreen ? 0 : props.theme.borders.radius.small}px;

  ${(props) =>
    props.isFullScreen
      ? css`
          height: 100%;
        `
      : css`
          @media (min-width: ${props.theme.layout.mobileBreakpoint}) {
            width: auto;
          }
        `}
`;

export const StickyFooter = styled.div`
  background-color: ${(props) => props.theme.palette.backgrounds.white};
  bottom: 0;
  position: sticky;
  padding-top: 0;
`;

export type DialogLayoutProps = {
  children: React.ReactNode;
  closeLabel: string;
  footer?: React.ReactNode;
  header: React.ReactNode;
  onClose: () => void;
  setDialogRef: Ref<HTMLDivElement>;
  dialogLabelKey: string;
  className?: string;
  isFullScreen?: boolean;
  isClosable?: boolean;
  usePxUnits?: boolean;
};

export const DialogLayout: React.FC<
  React.PropsWithChildren<DialogLayoutProps>
> = ({
  closeLabel,
  children,
  dialogLabelKey,
  footer,
  header,
  onClose,
  setDialogRef,
  className,
  isFullScreen,
  isClosable,
  usePxUnits,
}) => (
  <ResponsiveHelper>
    {(breakpoint) => (
      <DialogBox
        ref={setDialogRef}
        isFullScreen={isFullScreen}
        className={className}
      >
        <Spacings.Inset
          data-testid="dialog-header-content"
          scale={breakpoint === "mobile" ? "big" : "huge"}
          usePxUnits={usePxUnits}
        >
          <Spacings.Stack scale="big" usePxUnits={usePxUnits}>
            <ListItem
              alignItems="flex-start"
              right={
                isClosable && (
                  <IconButton
                    icon={<CloseIcon />}
                    label={closeLabel}
                    onClick={onClose}
                  />
                )
              }
            >
              <div id={dialogLabelKey}>{header}</div>
            </ListItem>
            <div>{children}</div>
          </Spacings.Stack>
        </Spacings.Inset>
        {footer && (
          <StickyFooter>
            <Divider />
            <Spacings.Inset
              data-testid="dialog-footer"
              scale={breakpoint === "mobile" ? "big" : "huge"}
              usePxUnits={usePxUnits}
            >
              {footer}
            </Spacings.Inset>
          </StickyFooter>
        )}
      </DialogBox>
    )}
  </ResponsiveHelper>
);

DialogLayout.defaultProps = {
  isClosable: true,
};
