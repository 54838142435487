import React from "react";
import styled, { css } from "styled-components";

import Spacings from "../Spacings";
import { SpacingScales } from "../Spacings/helpers";
import { colorByPriority, TextPriority } from "../Text";

const ListItemContent = styled.div<{ priority?: TextPriority }>`
  flex-grow: 1;

  /* To wrap this content to the next line in IE11 we have to use width: 100%.
    https://stackoverflow.com/questions/35111090/text-in-a-flex-container-doesnt-wrap-in-ie11
   */

  width: 100%;
  ${(props) =>
    props.priority &&
    css`
      color: ${colorByPriority(props.theme)[props.priority]};
    `};
`;

export const NoShrink = styled.div`
  flex-shrink: 0;
`;

type ListItemProps = {
  left?: React.ReactNode;
  right?: React.ReactNode;
  children?: React.ReactNode;
  alignItems?: string;
  scale?: SpacingScales;
  as?: keyof JSX.IntrinsicElements;
  priority?: TextPriority;
};

const ListItem: React.FC<React.PropsWithChildren<ListItemProps>> = ({
  children,
  left,
  right,
  alignItems = "center",
  scale,
  as,
  priority,
}) => (
  <Spacings.Inline alignItems={alignItems} scale={scale} as={as}>
    {left && <NoShrink>{left}</NoShrink>}
    {children ? (
      <ListItemContent priority={priority}>{children}</ListItemContent>
    ) : null}
    {right && <NoShrink>{right}</NoShrink>}
  </Spacings.Inline>
);

ListItem.defaultProps = {
  scale: "big",
};

export default ListItem;
