import React from "react";
import { createPortal } from "react-dom";
import styled from "styled-components";
import logger from "../../utils/logger";

import Spacings from "../Spacings";

export const StyledAlertMessage = styled.div`
  &:focus {
    outline: none;
  }
`;

const ALERT_MESSAGE_ELEMENT_ID = "ALERT_MESSAGE_ELEMENT_ID";

const MessageContainer = styled(Spacings.Stack).attrs({
  id: ALERT_MESSAGE_ELEMENT_ID,
  role: "alert",
})`
  /* in case there are no messages we can hide the container to avoid it having a
  margin even though it's empty */
  &:empty {
    display: none;
  }
`;

type AlertProps = {
  message: React.ReactNode;
  children?: (args: {
    onOpen: () => void;
    onClose: () => void;
  }) => React.ReactNode;
};
type AlertState = { isOpen: boolean; alertElement: HTMLElement | null };

class Alert extends React.Component<AlertProps> {
  static MessageContainer = MessageContainer;
  state: AlertState = {
    alertElement: null,
    isOpen: false,
  };
  private alertMessageRef = React.createRef<HTMLDivElement>();
  componentDidMount() {
    this.setState({
      alertElement: document.getElementById(ALERT_MESSAGE_ELEMENT_ID),
    });
  }
  toggleOpen = (isOpen: boolean) => {
    const alertElement = document.getElementById(ALERT_MESSAGE_ELEMENT_ID);
    if (!alertElement) {
      logger({
        message: "There is no alert message container rendered in the DOM.",
        status: "warn",
      });
    }
    this.setState({ isOpen, alertElement }, () => {
      if (this.alertMessageRef.current) {
        this.alertMessageRef.current.focus();
      }
    });
  };
  handleOpen = () => {
    this.toggleOpen(true);
  };
  handleClose = () => {
    this.toggleOpen(false);
  };
  render() {
    return (
      <React.Fragment>
        {this.props.children &&
          this.props.children({
            onClose: this.handleClose,
            onOpen: this.handleOpen,
          })}
        {this.state.isOpen || !this.props.children
          ? this.state.alertElement &&
            createPortal(
              <StyledAlertMessage ref={this.alertMessageRef} tabIndex={-1}>
                {this.props.message}
              </StyledAlertMessage>,
              this.state.alertElement,
            )
          : null}
      </React.Fragment>
    );
  }
}

export default Alert;
