import React from "react";
import styled, { css } from "styled-components";

import { Theme } from "../designTokens";
import { LinkStyleProps, linkStyles } from "../Link";
import TextCrop, { TextCropProps } from "../TextCrop";

const calcPxFontSize = (n: number, baseFontSize: number): number => {
  if (n === 0) {
    return baseFontSize - 2;
  }
  return (
    calcPxFontSize(n - 1, baseFontSize) + (Math.round((n - 1) / 4) + 1) * 2
  );
};

export type TextStyles =
  | "caption"
  | "body"
  | "bodyProlonged"
  | "headline6"
  | "headline5"
  | "headline4"
  | "headline3"
  | "headline2"
  | "headline1"
  | "subheadline"
  | "button1"
  | "button2";

export type TextPriority = "primary" | "secondary" | "error" | "accent";

export const fontSizeByTextStyle = (
  theme: Theme,
): { [textStyle in TextStyles]: number } =>
  Object.keys(theme.typography.textStylesScale).reduce(
    (fontSizes, textStyle) => {
      fontSizes[textStyle as TextStyles] =
        calcPxFontSize(
          theme.typography.textStylesScale[textStyle as TextStyles],
          theme.typography.baseFontSize,
        ) / theme.typography.baseFontSize;
      return fontSizes;
    },
    {} as { [textStyle in TextStyles]: number },
  );

export const lineHeightByTextStyle = (
  theme: Theme,
): { [textStyle in TextStyles]: number } => ({
  headline1: theme.typography.lineHeights.headline,
  headline2: theme.typography.lineHeights.headline,
  headline3: theme.typography.lineHeights.headline,
  headline4: theme.typography.lineHeights.headline,
  headline5: theme.typography.lineHeights.headline,
  headline6: theme.typography.lineHeights.headline,
  subheadline: theme.typography.lineHeights.headline,
  bodyProlonged: theme.typography.lineHeights.body,
  body: theme.typography.lineHeights.body,
  button1: theme.typography.lineHeights.body,
  button2: theme.typography.lineHeights.button,
  caption: theme.typography.lineHeights.body,
});

export type TextStyleProps = {
  textStyle: TextStyles;
  priority: TextPriority;
  isOnDarkBackground: boolean;
  isUncropped: boolean;
  isStrong: boolean;
} & TextCropProps &
  LinkStyleProps;

export const colorByPriority = (
  theme: Theme,
): { [priority in TextPriority]: string } => ({
  accent: theme.palette.accent.dark,
  error: theme.palette.error.main,
  primary: theme.palette.gray.main,
  secondary: theme.palette.gray.light,
});

export const colorOnDarkByPriority = (
  theme: Theme,
): { [priority in TextPriority]: string } => ({
  accent: theme.palette.accent.light,
  error: theme.palette.error.main,
  primary: theme.palette.white,
  secondary: theme.palette.brand[300],
});

export const textColor = (
  isOnDarkBackground: boolean,
  colorOnLight: string,
  colorOnDark?: string,
) => css`
  color: ${(props) =>
    isOnDarkBackground
      ? colorOnDark || props.theme.palette.white
      : colorOnLight};
`;

const htmlTagByTextStyle: {
  [textStyle in TextStyles]: keyof JSX.IntrinsicElements;
} = {
  body: "p",
  bodyProlonged: "p",
  button1: "span",
  button2: "span",
  caption: "p",
  headline1: "h1",
  headline2: "h2",
  headline3: "h3",
  headline4: "h4",
  headline5: "h5",
  headline6: "h6",
  subheadline: "h5",
};

/* stylelint-disable comment-empty-line-before */
export const cssByTextStyle = {
  headline1: css<TextStyleProps>`
    ${TextCrop};
    ${
      /* sc-declaration */ (props) =>
        textColor(
          props.isOnDarkBackground,
          colorByPriority(props.theme)[props.priority],
        )
    };
    font-weight: ${(props) => props.theme.typography.weights.regular};
    a {
      ${linkStyles}
    }
  `,
  headline2: css<TextStyleProps>`
    ${TextCrop};
    ${
      /* sc-declaration */ (props) =>
        textColor(
          props.isOnDarkBackground,
          colorByPriority(props.theme)[props.priority],
        )
    };
    font-weight: ${(props) => props.theme.typography.weights.regular};
    a {
      ${linkStyles}
    }
  `,
  headline3: css<TextStyleProps>`
    ${TextCrop};
    ${
      /* sc-declaration */ (props) =>
        textColor(
          props.isOnDarkBackground,
          colorByPriority(props.theme)[props.priority],
        )
    };
    font-weight: ${(props) => props.theme.typography.weights.regular};
    a {
      ${linkStyles}
    }
  `,
  headline4: css<TextStyleProps>`
    ${TextCrop};
    ${
      /* sc-declaration */ (props) =>
        textColor(
          props.isOnDarkBackground,
          colorByPriority(props.theme)[props.priority],
        )
    };
    font-weight: ${(props) => props.theme.typography.weights.semiBold};
    a {
      ${linkStyles}
    }
  `,
  headline5: css<TextStyleProps>`
    ${TextCrop};
    ${
      /* sc-declaration */ (props) =>
        textColor(
          props.isOnDarkBackground,
          colorByPriority(props.theme)[props.priority],
        )
    };
    font-weight: ${(props) => props.theme.typography.weights.semiBold};
    a {
      ${linkStyles}
    }
  `,
  headline6: css<TextStyleProps>`
    ${TextCrop};
    ${
      /* sc-declaration */ (props) =>
        textColor(
          props.isOnDarkBackground,
          colorByPriority(props.theme)[props.priority],
        )
    };
    font-weight: ${(props) => props.theme.typography.weights.semiBold};
    a {
      ${linkStyles}
    }
  `,
  subheadline: css<TextStyleProps>`
    ${TextCrop};
    ${
      /* sc-declaration */ (props) =>
        textColor(props.isOnDarkBackground, props.theme.palette.gray.light)
    };
    text-transform: uppercase;
    font-weight: ${(props) => props.theme.typography.weights.semiBold};
    letter-spacing: ${(props) =>
      props.theme.typography.letterSpacings.uppercaseText};
    a {
      ${linkStyles}
    }
  `,
  bodyProlonged: css<TextStyleProps>`
    ${TextCrop};
    ${
      /* sc-declaration */ (props) =>
        textColor(
          props.isOnDarkBackground,
          colorByPriority(props.theme)[props.priority],
        )
    };
    font-weight: ${(props) =>
      props.isStrong
        ? props.theme.typography.weights.semiBold
        : props.theme.typography.weights.regular};
    a {
      ${linkStyles}
    }
  `,
  body: css<TextStyleProps>`
    ${TextCrop};
    ${(props) =>
      textColor(
        props.isOnDarkBackground,
        colorByPriority(props.theme)[props.priority],
        colorOnDarkByPriority(props.theme)[props.priority],
      )};
    font-weight: ${(props) =>
      props.isStrong
        ? props.theme.typography.weights.semiBold
        : props.theme.typography.weights.regular};
    a {
      ${linkStyles}
    }
  `,
  button1: css<TextStyleProps>`
    ${TextCrop};
    ${
      /* sc-declaration */ (props) =>
        textColor(props.isOnDarkBackground, props.theme.palette.brand[800])
    };
    font-weight: ${(props) => props.theme.typography.weights.regular};
    max-width: none;
  `,
  button2: css<TextStyleProps>`
    ${TextCrop};
    ${
      /* sc-declaration */ (props) =>
        textColor(props.isOnDarkBackground, props.theme.palette.brand[800])
    };
    font-weight: ${(props) => props.theme.typography.weights.regular};
    max-width: none;
  `,
  caption: css<TextStyleProps>`
    ${TextCrop};
    ${
      /* sc-declaration */ (props) =>
        textColor(
          props.isOnDarkBackground,
          colorByPriority(props.theme)[props.priority],
        )
    };
    font-weight: ${(props) =>
      props.isStrong
        ? props.theme.typography.weights.semiBold
        : props.theme.typography.weights.regular};
    a {
      ${linkStyles}
    }
  `,
};
/* stylelint-enable comment-empty-line-before */

const getAdjustmentsForTextStyle = (textStyle: TextStyles) => {
  switch (textStyle) {
    case "headline1":
    case "headline2":
    case "headline3":
    case "subheadline":
      return {
        bottomAdjustment: 1,
        topAdjustment: 0,
      };
    case "button1":
      return {
        bottomAdjustment: 1,
        topAdjustment: 0,
      };
    case "button2":
      return {
        bottomAdjustment: 0,
        topAdjustment: 1,
      };
    case "headline4":
      return {
        bottomAdjustment: 1,
        topAdjustment: 0,
      };
    case "headline5":
      return {
        bottomAdjustment: 1,
        topAdjustment: 0,
      };
    case "bodyProlonged":
      return {
        bottomAdjustment: 0,
        topAdjustment: 1,
      };
    case "headline6":
    case "body":
      return {
        bottomAdjustment: 1,
        topAdjustment: 0,
      };
    case "caption":
      return {
        bottomAdjustment: 0,
        topAdjustment: 1,
      };
    default:
      return {
        bottomAdjustment: 0,
        topAdjustment: 0,
      };
  }
};

const Text = styled.p.attrs<TextProps>(
  ({
    theme,
    textStyle = "body",
    priority = "primary",
    isOnDarkBackground = false,
    as: renderAsTag,
    isUncropped = false,
    isStrong = false,
    className,
    usePxFontSize = false,
  }) => {
    const { bottomAdjustment, topAdjustment } =
      getAdjustmentsForTextStyle(textStyle);
    return {
      as: renderAsTag || htmlTagByTextStyle[textStyle],
      bottomAdjustment,
      className,
      fontSize: usePxFontSize
        ? `${
            fontSizeByTextStyle(theme)[textStyle] *
            theme.typography.baseFontSize
          }px`
        : `${fontSizeByTextStyle(theme)[textStyle]}rem`,
      isOnDarkBackground,
      isStrong,
      isUncropped,
      lineHeight: lineHeightByTextStyle(theme)[textStyle],
      priority,
      textStyle,
      topAdjustment,
    } as TextStyleProps;
  },
)<TextProps>`
  ${(props) =>
    // feel free to find a better solution 😬
    cssByTextStyle[props.textStyle!] as any}
`;

export type TextProps = {
  children?: React.ReactNode;
  as?: keyof JSX.IntrinsicElements;
  textStyle?: TextStyles;
  priority?: TextPriority;
  isOnDarkBackground?: boolean;
  isUncropped?: boolean;
  isStrong?: boolean;
  className?: string;
  usePxFontSize?: boolean;
};

export default Text;
