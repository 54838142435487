import React, { useLayoutEffect, useRef } from "react";
import styled from "styled-components";

import Button, {
  StyledButton,
} from "@finanzchef24gmbh/design-system/src/Button";
import Dialog from "@finanzchef24gmbh/design-system/src/Dialog";
import { DialogState } from "@finanzchef24gmbh/design-system/src/Dialog/useDialogState";
import ErrorSummary from "@finanzchef24gmbh/design-system/src/ErrorSummary";
import FormInput from "@finanzchef24gmbh/design-system/src/FormInput";
import ChevronRightIcon from "@finanzchef24gmbh/design-system/src/Icons/ChevronRightIcon";
import InputBox from "@finanzchef24gmbh/design-system/src/InputBox";
import ListItem from "@finanzchef24gmbh/design-system/src/ListItem";
import Markdown from "@finanzchef24gmbh/design-system/src/Markdown";
import Spacings from "@finanzchef24gmbh/design-system/src/Spacings";
import Text from "@finanzchef24gmbh/design-system/src/Text";
import ThreeDotLoader from "@finanzchef24gmbh/design-system/src/ThreeDotLoader";
import useIndustrySearch, {
  UseIndustrySearchArgs,
} from "../../hooks/useIndustrySearch";
import HighlightedIndustryButton from "../HighlightedIndustryButton";

const StyledInputBox = styled(InputBox)`
  display: flex;
  padding: 0;
`;

const StyledInput = styled(FormInput)`
  border: none;
  width: auto;
  flex-grow: 1;
  &:focus,
  &:focus-within {
    box-shadow: none;
  }
`;

const StyledLoader = styled(ThreeDotLoader)`
  /* The left and right dots of the loader are pseudo ::before and ::after
  elements which are outside of the central dots box. To avoid overlapping
  the loader with the button we move it further to the left. */
  left: -20px;
  margin: auto 0;
`;

export const VERTICAL_PADDING = 14;
export const HORIZONTAL_PADDING = 12;
const UnstyledButton = styled.button`
  all: inherit;
  display: flex;
  align-items: center;
  box-sizing: border-box;
  padding-left: ${HORIZONTAL_PADDING}px;
  padding-right: ${(props) =>
    HORIZONTAL_PADDING + props.theme.borders.width.medium}px;

  &:focus {
    box-shadow: 0 0 0 3px ${(props) => props.theme.palette.brand.light};
  }
`;

const StyledMarkdown = styled(Markdown)`
  flex: 1;
`;

export const InlineInputAndButton = styled.div`
  display: flex;

  ${InputBox} {
    border-right: 0;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }

  > ${StyledButton} {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
    border-top-right-radius: ${(props) => props.theme.borders.radius.medium}px;
    border-bottom-right-radius: ${(props) =>
      props.theme.borders.radius.medium}px;
    &:not(:focus) {
      box-shadow: none;
    }
  }
`;

const DIALOG_INPUT_ID = "autocomplete-input-dialog";

export type HighlightedIndustry = {
  id: string;
  name: string;
};

type Props = Omit<UseIndustrySearchArgs, "messages"> & {
  className?: string;
  dialogState: DialogState;
  highlightedIndustries?: HighlightedIndustry[];
  messages: UseIndustrySearchArgs["messages"] & {
    dialogHeadline: string;
    errorMessageQuery: string;
    errorTitle: string;
    highlightedIndustriesHeadline: string;
  };
  onHighlightedIndustrySelection?: (
    highlightedIndustry: HighlightedIndustry,
  ) => void;
  placeholder: string;
  usePxUnits?: boolean;
};

const IndustrySearchDialog: React.FC<React.PropsWithChildren<Props>> = ({
  allowSubmitWithoutSelection,
  backendUrl,
  className,
  dialogState,
  highlightedIndustries,
  messages,
  onHighlightedIndustrySelection,
  onIndustrySelection,
  onNavigation,
  placeholder,
  selectedProductTypeCombination,
  selectedProductTypeIds,
  tenant,
  usePxUnits,
}) => {
  const formInputRef = useRef<HTMLInputElement>(null);
  const {
    errorMessage,
    getVisibleItems,
    handleInputValueChange,
    handleItemSelection,
    handleSubmit,
    hasError,
    inputValue,
    loading,
  } = useIndustrySearch({
    allowSubmitWithoutSelection,
    backendUrl,
    messages: {
      errorMessageEmptyInput: messages.errorMessageEmptyInput,
      errorMessageEntryRouter: messages.errorMessageEntryRouter,
      notFound: messages.notFound,
    },
    onIndustrySelection,
    onNavigation,
    selectedProductTypeCombination,
    selectedProductTypeIds,
    tenant,
  });

  useLayoutEffect(() => {
    if (dialogState.visible && formInputRef.current) {
      formInputRef.current.focus();
    }
  }, [dialogState.visible, formInputRef.current]);

  const visibleItems = getVisibleItems();
  return (
    <Dialog
      isFullScreen
      visible={dialogState.visible}
      toggleRef={dialogState.toggle.ref}
      onClose={dialogState.close}
      className={className}
      usePxUnits={usePxUnits}
      header={
        <label htmlFor={DIALOG_INPUT_ID}>
          <Text textStyle="headline4" usePxFontSize={usePxUnits}>
            {messages.dialogHeadline}
          </Text>
        </label>
      }
      content={
        <Spacings.Stack scale="medium" usePxUnits={usePxUnits}>
          <form onSubmit={handleSubmit}>
            <InlineInputAndButton>
              <StyledInputBox>
                <StyledInput
                  ref={formInputRef}
                  id={DIALOG_INPUT_ID}
                  onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                    handleInputValueChange(event.target.value);
                  }}
                  placeholder={placeholder}
                  value={inputValue}
                />
                <StyledLoader isActive={loading} />
              </StyledInputBox>
              <Button
                buttonType="primary"
                scale="small"
                type="submit"
                data-testid="dialog-submit-button"
              >
                <ChevronRightIcon isOnDarkBackground />
              </Button>
            </InlineInputAndButton>
          </form>
          {hasError && (
            <ErrorSummary title={messages.errorTitle}>
              <Text priority="error" usePxFontSize={usePxUnits}>
                {errorMessage || messages.errorMessageQuery}
              </Text>
            </ErrorSummary>
          )}
          {visibleItems.length === 0 &&
            highlightedIndustries &&
            highlightedIndustries.length > 0 && (
              <Spacings.Stack usePxUnits={usePxUnits}>
                <Text textStyle="headline6" usePxFontSize={usePxUnits}>
                  {messages.highlightedIndustriesHeadline}
                </Text>
                <Spacings.Stack as="ul" usePxUnits={usePxUnits}>
                  {highlightedIndustries.map((industry) => (
                    <li key={industry.id}>
                      <HighlightedIndustryButton
                        name={industry.name}
                        onClick={() => {
                          handleItemSelection({
                            id: industry.id,
                            industryId: industry.id,
                            label: industry.name,
                            // We do not want to change the value of the input
                            // element to avoid a flickering before routing.
                            labelWithoutHighlights: "",
                          });
                          if (onHighlightedIndustrySelection) {
                            onHighlightedIndustrySelection(industry);
                          }
                        }}
                      />
                    </li>
                  ))}
                </Spacings.Stack>
              </Spacings.Stack>
            )}
          <Spacings.Stack as="ul" scale="medium" usePxUnits={usePxUnits}>
            {visibleItems.map((item) => (
              <ListItem key={item.id} as="li">
                <UnstyledButton
                  onClick={() => {
                    handleItemSelection(item);
                  }}
                >
                  <StyledMarkdown>{item.label}</StyledMarkdown>
                  <ChevronRightIcon color="brand" />
                </UnstyledButton>
              </ListItem>
            ))}
          </Spacings.Stack>
        </Spacings.Stack>
      }
      closeLabel="zurück"
    />
  );
};

export default IndustrySearchDialog;
