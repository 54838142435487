import { useTheme } from "styled-components";

import { Theme } from "../designTokens";

export type IconPrority = "primary" | "secondary";
export type IconColor =
  | "gray"
  | "brand"
  | "auxiliary"
  | "accent"
  | "error"
  | "currentColor";
export type Scale = "tiny" | "small" | "medium" | "big" | "huge" | "gigantic";

export type PassedProps = {
  priority?: IconPrority;
  color?: IconColor;
  isOnDarkBackground?: boolean;
  scale?: Scale;
};

export type MappedProps = {
  color: string;
  height?: string;
  width?: string;
};

export const colorByPriority = {
  primary: (theme: Theme, baseColor: IconColor = "gray") => {
    switch (baseColor) {
      case "accent":
        return theme.palette[baseColor].dark;
      case "brand":
        return theme.palette[baseColor].dark;
      case "currentColor":
        return "currentColor";
      default:
        return theme.palette[baseColor].main;
    }
  },
  secondary: (theme: Theme, baseColor: IconColor = "gray") => {
    switch (baseColor) {
      case "error":
        return theme.palette.error.main;
      case "brand":
        return theme.palette.brand.main;
      case "currentColor":
        return "currentColor";
      case "gray":
      case "accent":
      default:
        return theme.palette[baseColor].light;
    }
  },
};

export const getSizeByScale = (scale?: Scale) => {
  switch (scale) {
    case "gigantic":
      return "3em";
    case "huge":
      return "2em";
    case "big":
      return "1.5em";
    case "medium":
      return "1.25em";
    case "tiny":
      return "0.85em";
    case "small":
    default:
      return "1em";
  }
};

export const useMappedProps = ({
  isOnDarkBackground,
  priority = "primary",
  color,
  scale,
  ...rest
}: PassedProps): MappedProps => {
  const theme = useTheme();
  return {
    ...rest,
    color: isOnDarkBackground
      ? theme.palette.white
      : colorByPriority[priority](theme, color),
    height: getSizeByScale(scale),
    width: getSizeByScale(scale),
  };
};
