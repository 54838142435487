import styled from "styled-components";

const Divider = styled.div<{ priority?: "primary" | "secondary" }>`
  height: 0;
  border-top: 1px solid
    ${(props) =>
      props.priority === "secondary"
        ? props.theme.palette.gray[100]
        : props.theme.palette.gray[400]};
`;

Divider.defaultProps = {
  priority: "primary",
};

export default Divider;
