import React, { useEffect, useState } from "react";
import { useMediaQuery } from "react-responsive";
import { useTheme } from "styled-components";

type ResponsiveHelperProps = {
  children: (breakpoint: "desktop" | "tablet" | "mobile") => React.ReactNode;
};

const ResponsiveHelper: React.FC<ResponsiveHelperProps> = ({ children }) => {
  const theme = useTheme();
  const isDesktop = useMediaQuery({
    minWidth: theme.layout.tabletBreakpointPx,
  });
  const isTablet = useMediaQuery({
    maxWidth: theme.layout.tabletBreakpointPx - 1,
    minWidth: theme.layout.mobileBreakpointPx,
  });
  const [hasMounted, setHasMounted] = useState(false);
  useEffect(() => {
    setHasMounted(true);
  }, []);

  // This is for ssr rendering
  if (!hasMounted) {
    return <>{children("desktop")}</>;
  }

  return (
    <>
      {isDesktop
        ? children("desktop")
        : isTablet
          ? children("tablet")
          : children("mobile")}
    </>
  );
};

export default ResponsiveHelper;
