import React from "react";
import ReactDOM from "react-dom";
import Helmet from "react-helmet";
import {
  createGlobalStyle,
  StyleSheetManager,
  ThemeProvider,
} from "styled-components";

import designSystemTheme from "@finanzchef24gmbh/design-system/src/designTokens";
import { pxOrRem } from "@finanzchef24gmbh/design-system/src/Spacings/helpers";
import { createHomepageTheme } from "@finanzchef24gmbh/homepage/src/createHomePageTheme";
import shouldForwardProp from "./utils/shouldForwardProp";
import InterUiRegularWoff from "./fonts/Inter-Regular.woff";
import InterUiRegularWoff2 from "./fonts/Inter-Regular.woff2";
import InterUiSemiBoldWoff from "./fonts/Inter-SemiBold.woff";
import InterUiSemiBoldWoff2 from "./fonts/Inter-SemiBold.woff2";
import getGlobalStyles from "./globalStyles";

import App from "./App";

const homepageTheme = createHomepageTheme(designSystemTheme, 1100);
const GlobalStyles = createGlobalStyle`
  @font-face {
    font-family: 'Inter UI';
    src:  url('https://widget.finanzchef24.de/${InterUiSemiBoldWoff2}') format('woff2'),
          url('https://widget.finanzchef24.de/${InterUiSemiBoldWoff}') format('woff');
    font-weight: 600;
    font-style: normal;
    font-display: swap;
  }
  @font-face {
    font-family: 'Inter UI';
    src:  url('https://widget.finanzchef24.de/${InterUiRegularWoff2}') format('woff2'),
          url('https://widget.finanzchef24.de/${InterUiRegularWoff}') format('woff');
    font-weight: 400;
    font-style: normal;
    font-display: swap;
  }

  .fc24-widget,
  .fc24-widget-dialog {
    ${getGlobalStyles()}

    font-family: 'Inter UI', 'Helvetica Neue', Helvetica, Arial, sans-serif !important;
    font-feature-settings: "tnum";
    line-height: 1;
    -webkit-font-smoothing: auto;

    /*
    Overwrite the browsers defaults for these elements as some browsers e.g.
    Chrome set these elements to be system-ui which is more specific than the
    bodys font family.
    Also a CSS reset is not enough here, as it only sets the font to inherit
    which causes those elements to inherit the default browser styles again.
    */
    input, textarea, select, button {
      font-family: 'Inter UI', 'Helvetica Neue', Helvetica, Arial, sans-serif !important;
    }
  }

  .fc24-widget-dialog-container {
    z-index: 9999;
    margin: 0;
  }

  .fc24-widget-dialog {
    h4, h5, h6, p {
      margin: 0;
      font-family: 'Inter UI', 'Helvetica Neue', Helvetica, Arial, sans-serif !important;
    }

    h4 {
      font-size: 22px;
    }

    input {
      font-size: 18px;

      ::placeholder {
        color: rgba(0, 0, 0, 0.6) !important;
      }
    }

    button {
      svg {
        font-size: 16px;
      }
    }

    ul {
      margin-top: ${(props) =>
        pxOrRem({
          remValue: props.theme.spacings.medium,
          theme: props.theme,
          usePxUnits: true,
        })};

      li {
        margin-bottom: 0;

        button {
          > div {
            > div, > p {
              font-size: 16px;

              span {
                font-size: 16px;
              }
            }
          }
        }
      }
    }
  }
`;

declare global {
  interface Window {
    FC24Widget: any;
  }
}

export type ConfigProps = {
  config: {
    widgetContainers: HTMLDivElement[];
    partnerName?: string;
    awinAffId?: string;
    contentfulWidgetId?: string;
  };
};

window.FC24Widget = {
  init: ({ config }: ConfigProps) =>
    config.widgetContainers.forEach((widgetContainer) => {
      const { awinAffId, contentfulWidgetId, partnerName } = config;

      ReactDOM.render(
        <StyleSheetManager shouldForwardProp={shouldForwardProp}>
          <ThemeProvider theme={homepageTheme}>
            <GlobalStyles />
            <Helmet>
              <link
                rel="preload"
                href={`https://widget.finanzchef24.de/${InterUiRegularWoff2}`}
                as="font"
                type="font/woff2"
                crossOrigin=""
              />
              <link
                rel="preload"
                href={`https://widget.finanzchef24.de/${InterUiSemiBoldWoff2}`}
                as="font"
                type="font/woff2"
                crossOrigin=""
              />
            </Helmet>
            <App
              awinAffId={awinAffId}
              contentfulWidgetId={contentfulWidgetId}
              partnerName={partnerName}
            />
          </ThemeProvider>
        </StyleSheetManager>,
        widgetContainer,
      );
    }),
};
