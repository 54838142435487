import { Theme as DefaultTheme } from "@finanzchef24gmbh/design-system/src/designTokens";

export const BROWSER_DEFAULT_FONT_SIZE = 16;

export const createHomepageTheme = (
  theme: DefaultTheme,
  containerWidth: number,
) => ({
  ...theme,
  borders: {
    ...theme.borders,
    radius: {
      ...theme.borders.radius,
      big: 20,
    },
  },
  layout: {
    ...theme.layout,
    articleWidth: "90ch",
    containerWidth: `${containerWidth / BROWSER_DEFAULT_FONT_SIZE}em`,
    containerWidthBreakpoint: `${containerWidth / BROWSER_DEFAULT_FONT_SIZE}em`,
    formWidth: `${containerWidth / BROWSER_DEFAULT_FONT_SIZE}em`,
    mobileMaxWidthBreakpoint: `${749 / BROWSER_DEFAULT_FONT_SIZE}em`,
    tabletBreakpoint: `${containerWidth / BROWSER_DEFAULT_FONT_SIZE}em`,
    tabletMaxWidthBreakpoint: `${
      (containerWidth - 1) / BROWSER_DEFAULT_FONT_SIZE
    }em`,
  },
  palette: {
    ...theme.palette,
    gray: {
      ...theme.palette.gray,
      25: "hsla(193,3%,98%,1)",
      light: theme.palette.gray[700],
    },
  },
  typography: {
    ...theme.typography,
    baseFontSize: BROWSER_DEFAULT_FONT_SIZE,
    lineHeights: {
      ...theme.typography.lineHeights,
      body: 1.7,
      headline: 1.3,
    },
    // ~95 characters per line without counting white space
    lineLength: "90ch",
    textStylesScale: {
      body: 1,
      bodyProlonged: 2,
      button1: 2,
      button2: 1,
      caption: 0,
      headline1: 7,
      headline2: 6,
      headline3: 5,
      headline4: 3,
      headline5: 2,
      headline6: 1,
      subheadline: 1,
    },
  },
});

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface Theme extends ReturnType<typeof createHomepageTheme> {}
