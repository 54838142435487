import { useEffect, useState } from "react";

export type EkomiProps = {
  imgSrc?: string;
  link?: string;
  rating?: number;
  seal?: string;
};

const useEkomiRating = () => {
  const [ekomi, setEkomi] = useState({});

  useEffect(() => {
    (async () => {
      const getEkomi = await fetch(`${process.env.WIDGET_ORIGIN}/api/ekomi`)
        .then((res) => res.json())
        .then((data) => data);

      setEkomi(getEkomi);
    })();
  }, []);

  return ekomi;
};

export default useEkomiRating;
