import Inline from "../Inline";
import Inset from "../Inset";
import InsetSquish from "../InsetSquish";
import Stack from "../Stack";
import Switcher from "../Switcher";

const Spacings = {
  Inline,
  Inset,
  InsetSquish,
  Stack,
  Switcher,
};

export default Spacings;
