import { useEffect, useState } from "react";

import { ConfigProps } from "..";
import { AppProps } from "../App";

type ContentfulDataProps = {
  awinAffId: ConfigProps["config"]["awinAffId"];
  contentfulWidgetId: ConfigProps["config"]["contentfulWidgetId"];
  partnerName: ConfigProps["config"]["partnerName"];
};

const useContentfulData = ({
  awinAffId,
  contentfulWidgetId,
  partnerName,
}: ContentfulDataProps): AppProps["homepageContentfulData"] => {
  const [contentfulData, setContentfulData] = useState({});
  const fallbackContentfulWidgetId = "6dzNLgky0wxpJ7IEg6FqH1";
  const params = `/?contentfulWidgetId=${
    contentfulWidgetId || fallbackContentfulWidgetId
  }&awinAffId=${awinAffId}&partnerName=${partnerName}`;

  useEffect(() => {
    (async () => {
      const getContentfulData = await fetch(
        `${process.env.WIDGET_ORIGIN}/api/contentful${params}`,
      )
        .then((res) => res.json())
        .then((data) => data);

      setContentfulData(getContentfulData);
    })();
  }, [params]);

  return contentfulData;
};

export default useContentfulData;
