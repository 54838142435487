import React, { useCallback, useRef } from "react";

import BaseIndustrySearch from "@finanzchef24gmbh/insurance-product-system/src/components/IndustrySearch";
import { HighlightedIndustry } from "@finanzchef24gmbh/insurance-product-system/src/components/IndustrySearchDialog";
import { ConfigProps } from "../..";
import { AppProps } from "../../App";
import { buildAwinLink, isServerSide } from "../../utils";

type IndustrySearchProps = {
  awinAffId?: ConfigProps["config"]["awinAffId"];
  partnerName?: ConfigProps["config"]["partnerName"];
  buttonText?: string;
  buttonTextMobile?: string;
  elementId?: string;
  highlightedIndustries?: HighlightedIndustry[];
  placeholder?: string;
  selectedProductTypeIds?: string[];
  selectedProductTypeCombination?: string;
  showHighlightedIndustriesInTheAutocomplete?: boolean;
  customerFrontendOrigin?: AppProps["customerFrontendOrigin"];
  homepageOrigin?: AppProps["homepageOrigin"];
};

const IndustrySearch: React.FC<IndustrySearchProps> = ({
  awinAffId,
  buttonText,
  buttonTextMobile,
  customerFrontendOrigin,
  elementId = "fc24-ac-input",
  homepageOrigin,
  highlightedIndustries,
  partnerName,
  placeholder,
  selectedProductTypeIds,
  selectedProductTypeCombination,
  showHighlightedIndustriesInTheAutocomplete,
}) => {
  let windowRef = !isServerSide ? useRef<Window>(window) : useRef(null);

  useCallback(() => {
    if (windowRef.current === null) {
      windowRef = useRef<Window>(window);
    }
  }, []);

  return (
    <BaseIndustrySearch
      className="fc24-widget-dialog"
      mobileId={`${elementId}-dialog`}
      desktopId={elementId}
      messages={{
        button: buttonText || "Los geht's",
        buttonMobile: buttonTextMobile,
        dialogHeadline: "Ihre Tätigkeit:",
        errorMessageEmptyInput: "Bitte geben Sie Ihre Tätigkeit ein.",
        errorMessageEntryRouter:
          "Es gab ein Problem bei der Auswahl Ihrer Tätigkeit. Unser Team wird über diesen Fehler informiert und wird ihn sobald wie möglich beheben.",
        errorMessageQuery:
          "Es gab ein Problem beim Laden der Tätigkeiten. Unser Team wird über diesen Fehler informiert und wird ihn sobald wie möglich beheben.",
        errorTitle: "Es gibt ein Problem.",
        highlightedIndustriesHeadline: "Meist gewählte Tätigkeiten:",
        highlightedIndustriesTitle: "Meist gewählte Tätigkeiten:",
        loading: "Tätigkeiten werden geladen...",
        notFound: (inputValue) =>
          `Tätigkeit **${inputValue}** nicht gefunden? **Kontaktieren Sie uns!**`,
      }}
      backendUrl={`${process.env.ORIGIN || homepageOrigin}/backend`}
      placeholder={placeholder || "Bitte Tätigkeit eingeben..."}
      selectedProductTypeIds={selectedProductTypeIds}
      selectedProductTypeCombination={selectedProductTypeCombination}
      allowSubmitWithoutSelection
      highlightedIndustries={highlightedIndustries}
      showHighlightedIndustriesInTheAutocomplete={
        showHighlightedIndustriesInTheAutocomplete
      }
      onIndustrySelection={() => {
        windowRef.current = window.open()!;
      }}
      onNavigation={(entryRouterResult) => {
        const entryRouterLink =
          entryRouterResult.application === "customer-frontend"
            ? `${process.env.CUSTOMER_FRONTEND || customerFrontendOrigin}${
                entryRouterResult.maskedPath || entryRouterResult.path
              }`
            : `${process.env.ORIGIN || homepageOrigin}${
                entryRouterResult.path
              }`;

        const industrySearchLink = buildAwinLink({
          awinAffId,
          link: entryRouterLink,
          partnerName,
        });

        if (windowRef.current) {
          windowRef.current.location.href = industrySearchLink;
        }
      }}
      tenant="fc24"
      usePxUnits
    />
  );
};

export default IndustrySearch;
