import styled from "styled-components";

export type InsetSquishProps = {
  scale?: "tiny" | "small" | "medium" | "big" | "huge";
};

export const InsetSquish = styled.div<InsetSquishProps>`
  padding: ${(props) => props.theme.insetSquish.spacings[props.scale!]}px
    ${(props) =>
      props.theme.insetSquish.spacings[props.scale!] *
      props.theme.insetSquish.squishRatio}px;
`;

InsetSquish.defaultProps = {
  scale: "small",
} as Partial<InsetSquishProps>;

export default InsetSquish;
