import { TenantSettingsInstance } from "@finanzchef24gmbh/tenant-settings";
import defaultsDeep from "lodash/defaultsDeep";
import transparentize from "polished/lib/color/transparentize";

type ColorPalette = {
  100: string;
  200: string;
  300: string;
  400: string;
  500: string;
  600: string;
  700: string;
  800: string;
  900: string;
};
const blue: ColorPalette = {
  100: "#E6F8FF",
  200: "#CDEEFC",
  300: "#A1D9F0",
  400: "#36A5D2",
  500: "#127CA7",
  600: "#05668D",
  700: "#004866",
  800: "#003E57",
  900: "#001A25",
};
const orange: ColorPalette = {
  100: "#FFCEC7",
  200: "#FFB7AB",
  300: "#FF9D8D",
  400: "#FE8876",
  500: "#EA6955",
  600: "#E35244",
  700: "#CE3F32",
  800: "#78211B",
  900: "#5B1813",
};
const mint: ColorPalette = {
  100: "#E1FAF8",
  200: "#ABEDE9",
  300: "#7DE2DC",
  400: "#5ADAD1",
  500: "#2CD2C7",
  600: "#00BFB2",
  700: "#00988D",
  800: "#00655E",
  900: "#003330",
};
const red: ColorPalette = {
  100: "#FDE9E8",
  200: "#F6BABA",
  300: "#EE8E8D",
  400: "#E76563",
  500: "#DF413C",
  600: "#C03531",
  700: "#A22B27",
  800: "#83221E",
  900: "#641916",
};

type GrayColorPalette = ColorPalette & { 50: string };

const gray: GrayColorPalette = {
  /* background */
  50: "#EEF2F3",
  /* shadows */
  100: "#D8DEE0",
  200: "#C1CACC",
  300: "#ABB6B9",
  /* border */
  400: "#94A2A5",
  500: "#7C8E92",
  /* secondary text */
  600: "#556F76",
  700: "#3F555A",
  /* primary text */
  800: "#26393E",
  900: "#011C22",
};

type Colors = {
  gray: GrayColorPalette;
  black: string;
  white: string;
  red: ColorPalette;
  [colorName: string]: string | ColorPalette;
};

const defaultColors: Colors = {
  blue,
  orange,
  mint,
  gray,
  red,
  white: "white",
  black: "#0d0d0d",
};

type ColorMappingOptions = ReturnType<TenantSettingsInstance["getThemeColors"]>;

const mapColorsToPalette = (options?: ColorMappingOptions) => {
  const brandColor = options ? options.brandColor : "blue";
  const accentColor = options ? options.accentColor : "orange";
  const auxiliaryColor = options ? options.auxiliaryColor : "mint";
  const overrideColors = options ? options.overrideColors : {};
  const colors: Colors = defaultsDeep({}, overrideColors, defaultColors);
  return {
    brand: {
      light: colors[brandColor][300],
      main: colors[brandColor][500],
      dark: colors[brandColor][600],
      ...(colors[brandColor] as ColorPalette),
    },
    accent: {
      light: colors[accentColor][400],
      main: colors[accentColor][500],
      dark: colors[accentColor][700],
      ...(colors[accentColor] as ColorPalette),
    },
    auxiliary: {
      light: colors[auxiliaryColor][300],
      main: colors[auxiliaryColor][600],
      dark: colors[auxiliaryColor][700],
      ...(colors[auxiliaryColor] as ColorPalette),
    },
    error: {
      ...colors.red,
      light: colors.red[100],
      main: colors.red[500],
      dark: colors.red[900],
    },
    gray: {
      ...colors.gray,
      light: colors.gray[600],
      main: colors.gray[800],
      dark: colors.gray[800],
    },
    mint: colors.mint,
    white: colors.white,
    black: colors.black,
    borders: {
      main: colors.gray[400],
      light: colors.gray[100],
    },
    backgrounds: {
      accent: colors[accentColor][500],
      brand: colors[brandColor][500],
      gray: colors.gray[50],
      transparent: transparentize(0.5)(colors.gray[400]),
      white: colors.white,
    },
    visitedLink: "#609",
  };
};

const spacings = {
  tiny: "0.25rem",
  small: "0.5rem",
  medium: "1rem",
  big: "1.5rem",
  huge: "2rem",
  massive: "3rem",
  gigantic: "4rem",
  astronomic: "6rem",
};

const insetSquish = {
  spacings: {
    tiny: 4,
    small: 8,
    medium: 12,
    big: 16,
    huge: 24,
  },
  squishRatio: 1.5,
};

const borders = {
  width: {
    small: 1,
    medium: 2,
    big: 3,
  },
  radius: {
    small: 3,
    medium: 5,
  },
};

const inputControls = {
  radioAndCheckboxSizes: {
    small: "1.1rem",
    medium: "1.7rem",
    big: "2.3rem",
  },
};

const typography = {
  baseFontSize: 14,

  lineLength: "60ch",

  lineHeights: {
    button: 1.3,
    body: 1.5,
    form: 1.15,
    headline: 1.1,
  },

  weights: {
    regular: 400,
    semiBold: 600,
  },

  letterSpacings: {
    normal: "normal",
    uppercaseText: "0.1em",
  },

  textStylesScale: {
    headline1: 6,
    headline2: 5,
    headline3: 4,
    headline4: 3,
    headline5: 2,
    headline6: 1,
    subheadline: 1,
    bodyProlonged: 2,
    body: 1,
    button1: 2,
    button2: 1,
    caption: 0,
  },
};

const containerWidth = 1100;
const BROWSER_DEFAULT_FONT_SIZE = 16;

/**
 * NOTE: we can't use the same font size reference point for media queries and width units.
 * Because when em and rem units are used in media queries they are not be affected by changes
 * in font-size in the HTML since they’re based on the browser’s internal font-size property.
 * https://zellwk.com/blog/media-query-units/
 */
const layout = {
  containerWidth: `${containerWidth / typography.baseFontSize}em`,
  containerWidthBreakpoint: `${containerWidth / BROWSER_DEFAULT_FONT_SIZE}em`,
  mobileBreakpoint: `${750 / BROWSER_DEFAULT_FONT_SIZE}em`,
  mobileBreakpointPx: 750,
  sideBarWidth: `${350 / typography.baseFontSize}em`,
  tabletBreakpoint: `${containerWidth / BROWSER_DEFAULT_FONT_SIZE}em`,
  tabletBreakpointPx: containerWidth,
  formWidth: `${containerWidth / typography.baseFontSize}em`,
  fieldWidth: "55ch",
};

// NOTE: we are not sure if we want to keep these z indexes in the design system
// or not because they are application specific and not design system specific.
// At least not at the moment. It's fine for now as our design system is soley
// used for the customer-frontend, but in the future when we expand the system usage we
// should rethink this approach.
const zIndexes = {
  dialog: 4,
  header: 2,
  loadingSpinner: 4,
  toolbar: 1,
};

export interface Theme {
  borders: typeof borders;
  inputControls: typeof inputControls;
  insetSquish: typeof insetSquish;
  layout: typeof layout;
  palette: ReturnType<typeof mapColorsToPalette>;
  spacings: typeof spacings;
  ssr: { deviceCategory: "desktop" | "tablet" | "mobile" | null };
  typography: typeof typography;
  zIndexes: typeof zIndexes;
}

const theme: Theme = {
  borders,
  inputControls,
  insetSquish,
  layout,
  palette: mapColorsToPalette(),
  spacings,
  ssr: { deviceCategory: null },
  typography,
  zIndexes,
};

export const overrideThemeColors = (options?: ColorMappingOptions) => ({
  borders,
  inputControls,
  insetSquish,
  layout,
  palette: mapColorsToPalette(options),
  spacings,
  ssr: { deviceCategory: null },
  typography,
  zIndexes,
});

export default theme;
