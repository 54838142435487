export const isTouchDevice = () => {
  const prefixes = " -webkit- -moz- -o- -ms- ".split(" ");
  const mq = (q: string) => window.matchMedia(q).matches;

  // Works in some older browsers to detect touch support, but is deprecated
  if ((window as any).DocumentTouch) {
    return true;
  }
  if ("ontouchstart" in window) {
    return true;
  }

  // include the 'heartz' as a way to have a non matching MQ to help terminate the join
  // https://git.io/vznFH
  const query = ["(", prefixes.join("touch-enabled),("), "heartz", ")"].join(
    "",
  );
  return mq(query);
};
