import styled from "styled-components";

import { mapScaleToPixels, SpacingScales } from "../Spacings/helpers";

export type InsetProps = {
  scale?: SpacingScales;
  usePxUnits?: boolean;
};

const Inset = styled.div<InsetProps>`
  padding: ${(props) =>
    mapScaleToPixels(props.scale!, props.theme, props.usePxUnits)};
`;

Inset.defaultProps = {
  scale: "small",
};

export default Inset;
