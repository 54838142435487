import React from "react";
import styled from "styled-components";

import FormInputGroup from "../FormInputGroup";
import InputBox, { InputBoxProps } from "../InputBox";

type StyledFormInputProps = {
  expectedLength?: number;
};

export const StyledFormInput = styled(InputBox)<StyledFormInputProps>`
  /* this is a super rare custom padding in the design system and it is used to
  align selects and inputs perfectly */
  padding: 10px 12px;
  box-sizing: ${(props) =>
    props.expectedLength ? "content-box" : "border-box"};
  font-feature-settings: normal;
  width: ${(props) =>
    props.expectedLength ? `${props.expectedLength}ch` : "100%"};

  ${/* sc-selector */ FormInputGroup} > &:not(:last-child) {
    flex-grow: 1;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;

    /* bump z-index by one so that the focus box-shadow overlaps the suffix */
    z-index: 1;
  }

  ${/* sc-selector */ FormInputGroup} > &:not(:first-child) {
    flex-grow: 1;
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;

    /* bump z-index by one so that the focus box-shadow overlaps the prefix */
    z-index: 1;
  }
`;

type FormInputProps = InputBoxProps &
  StyledFormInputProps &
  JSX.IntrinsicElements["input"];

const FormInput = React.memo(
  React.forwardRef<HTMLInputElement, FormInputProps>((props, ref) => (
    <StyledFormInput as="input" {...props} scale={props.scale} ref={ref} />
  )),
);

export default FormInput;
