import Downshift, {
  ControllerStateAndHelpers,
  DownshiftState,
  StateChangeOptions,
} from "downshift";
import React, { createRef } from "react";
import styled, { css } from "styled-components";

import FormInput from "../FormInput";
import IconButton from "../IconButton";
import CloseIcon from "../Icons/CloseIcon";
import InputBox, { InputBoxProps } from "../InputBox";
import Layer from "../Layer";
import Spacings from "../Spacings";
import Text from "../Text";
import ThreeDotLoader from "../ThreeDotLoader";

const StyledWrapper = styled.div`
  position: relative;
`;

const StyledItem = styled(Spacings.InsetSquish)`
  position: relative;
  display: block;
`;

const ActionableStyledItem = styled(StyledItem)<{
  isActive?: boolean;
  isSelected?: boolean;
}>`
  cursor: pointer;

  ${(props) =>
    props.isActive
      ? css`
          background: ${props.theme.palette.backgrounds.gray};
        `
      : ""}
  ${(props) =>
    props.isSelected
      ? css`
          font-weight: bold;
        `
      : ""}
`;

const StyledMenu = styled(Layer.Raised)<{
  isOpen?: boolean;
  usePxUnits?: boolean;
}>`
  position: absolute;
  z-index: ${(props) => props.theme.zIndexes.dialog};
  left: 0;
  right: 0;
  background-color: ${(props) => props.theme.palette.white};
  max-height: ${(props) => (props.usePxUnits ? "320px" : "20rem")};
  /* avoid overflow when the menu is not open for whatever reason leads to IE11
  rendering a infinitely high empty list that overlays the rest of the page
  https://finanzchef24.atlassian.net/browse/FC-18767 */
  overflow-y: ${(props) => (props.isOpen ? "auto" : "hidden")};
  overflow-x: hidden;
  outline: 0;
  border-radius: 0 0 ${(props) => props.theme.borders.radius.medium}px
    ${(props) => props.theme.borders.radius.medium}px;
  border: ${(props) => props.theme.borders.width.small}px solid
    ${(props) => props.theme.palette.brand[500]};
  border-top-width: 0;
  ${(props) =>
    props.isOpen
      ? ""
      : css`
          border: none;
        `}
`;

const StyledInputBox = styled(InputBox)<{ isOpen?: boolean }>`
  padding: 0;
  ${(props) =>
    props.isOpen
      ? css`
          border-bottom-left-radius: 0;
          border-bottom-right-radius: 0;
        `
      : ""}
`;

const StyledInput = styled(FormInput)<{ isOpen?: boolean }>`
  border: none;
  width: auto;
  flex-grow: 1;
  &:focus,
  &:focus-within {
    box-shadow: none;
  }

  ${(props) =>
    props.isOpen
      ? css`
          border-bottom-left-radius: 0;
          border-bottom-right-radius: 0;
        `
      : ""}
`;

const StyledWrapContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
`;

const StyledSelectedItem = styled(Spacings.InsetSquish)<{
  isLastItem: boolean;
}>`
  border-radius: ${(props) => props.theme.borders.radius.small}px;
  background-color: ${(props) => props.theme.palette.brand[200]};
  margin: 3px 0 ${(props) => (props.isLastItem ? "3px" : "0")} 3px;
  height: 100%;
`;

const StyledThreeDotLoader = styled(ThreeDotLoader).attrs({
  scale: "small",
})`
  margin: auto 20px auto 4px;
`;

type Item = { id: string; label: string };

type BaseProps<I> = InputBoxProps &
  Pick<
    JSX.IntrinsicElements["input"],
    "id" | "name" | "onBlur" | "placeholder"
  > & {
    inputValue?: string;
    isLoading?: boolean;
    loadingText?: React.ReactNode;
    onInputValueChange?: (
      inputValue: string,
      downshift: ControllerStateAndHelpers<I>,
    ) => void;
    renderItem: (
      item: I,
      options: { isActive: boolean; isSelected: boolean },
    ) => React.ReactNode;
    visibleItems: (downshift: ControllerStateAndHelpers<I>) => I[];
    menuTitle?: React.ReactNode;
    labelId?: string;
    usePxUnits?: boolean;
  };

type AutocompleteInputProps<I> = BaseProps<I> & {
  clearInputValueOnSelection?: boolean;
  onSelectionChange: (
    selectedItems: I[],
    downshift: ControllerStateAndHelpers<I>,
  ) => void;
  selectedItems: I[];
};

class AutocompleteInput<I extends Item = Item> extends React.Component<
  AutocompleteInputProps<I>
> {
  input = createRef<HTMLInputElement>();

  stateReducer = (state: DownshiftState<I>, changes: StateChangeOptions<I>) => {
    switch (changes.type) {
      case Downshift.stateChangeTypes.keyDownEnter:
      case Downshift.stateChangeTypes.clickItem:
        return {
          ...changes,
          highlightedIndex: state.highlightedIndex,
          inputValue: this.props.clearInputValueOnSelection
            ? ""
            : state.inputValue,
          isOpen: true,
        };
      default:
        return changes;
    }
  };

  handleSelection = (
    selectedItem: I | null,
    downshift: ControllerStateAndHelpers<I>,
  ) => {
    // we can assume that `selectedItem` will never be null because we don't
    // render any UI control to clear all selected options
    if (this.props.selectedItems.find(({ id }) => id === selectedItem!.id)) {
      this.removeItem(selectedItem!, downshift);
    } else {
      this.addSelectedItem(selectedItem!, downshift);
    }
  };

  removeItem = (item: I, downshift: ControllerStateAndHelpers<I>) => {
    this.props.onSelectionChange(
      this.props.selectedItems.filter(({ id }) => id !== item.id),
      downshift,
    );
  };

  addSelectedItem(item: I, downshift: ControllerStateAndHelpers<I>) {
    this.props.onSelectionChange(
      [...this.props.selectedItems, item],
      downshift,
    );
  }

  render() {
    return (
      <Downshift
        itemToString={(item: I | null) => (item ? item.label : "")}
        stateReducer={this.stateReducer}
        onChange={this.handleSelection}
        selectedItem={null}
        inputValue={this.props.inputValue}
        labelId={this.props.labelId}
      >
        {(downshift) => {
          const {
            getInputProps,
            getMenuProps,
            isOpen,
            inputValue,
            getItemProps,
            highlightedIndex,
            toggleMenu,
          } = downshift as ControllerStateAndHelpers<I>;
          const visibleItems = this.props.visibleItems(downshift);
          const isOpenAndHasVisibleItems = isOpen && visibleItems.length > 0;
          return (
            <div>
              <StyledWrapper>
                <StyledInputBox
                  isOpen={isOpenAndHasVisibleItems}
                  data-testid="autocomplete-input-box"
                  invalid={this.props.invalid}
                  onClick={() => {
                    toggleMenu();
                    if (!isOpen && this.input.current) {
                      this.input.current.focus();
                    }
                  }}
                >
                  <StyledWrapContainer>
                    {this.props.selectedItems.map((item, index) => (
                      <StyledSelectedItem
                        key={item.id}
                        scale="tiny"
                        isLastItem={
                          index === this.props.selectedItems.length - 1
                        }
                      >
                        <Spacings.Inline
                          alignItems="center"
                          usePxUnits={this.props.usePxUnits}
                        >
                          <Text
                            textStyle="bodyProlonged"
                            isUncropped
                            usePxFontSize={this.props.usePxUnits}
                          >
                            {item.label}
                          </Text>
                          <IconButton
                            icon={<CloseIcon scale="tiny" />}
                            label={`Option ${item.label} entfernen`}
                            onClick={(event) => {
                              event.stopPropagation();
                              this.removeItem(item, downshift);
                            }}
                          />
                        </Spacings.Inline>
                      </StyledSelectedItem>
                    ))}
                    <StyledInput
                      {...getInputProps({
                        id: this.props.id,
                        isOpen: isOpenAndHasVisibleItems,
                        name: this.props.name,
                        onBlur: this.props.onBlur,
                        onChange: (
                          event: React.ChangeEvent<HTMLInputElement>,
                        ) => {
                          if (this.props.onInputValueChange) {
                            this.props.onInputValueChange(
                              event.target.value,
                              downshift,
                            );
                          }
                        },
                        onKeyDown: (
                          event: React.KeyboardEvent<HTMLInputElement>,
                        ) => {
                          if (event.keyCode === 8 && !inputValue) {
                            this.removeItem(
                              this.props.selectedItems[
                                this.props.selectedItems.length - 1
                              ],
                              downshift,
                            );
                          }
                        },
                        placeholder:
                          this.props.selectedItems.length === 0
                            ? this.props.placeholder
                            : undefined,
                        ref: this.input,
                      })}
                      // Without this we get a Typescript error.
                      as={FormInput}
                    />
                    {this.props.isLoading && <StyledThreeDotLoader isActive />}
                  </StyledWrapContainer>
                </StyledInputBox>
                <StyledMenu
                  data-testid="autocomplete-list-box"
                  isOpen={isOpenAndHasVisibleItems}
                  usePxUnits={this.props.usePxUnits}
                  {...getMenuProps()}
                >
                  {isOpen && (
                    <>
                      {(this.props.isLoading || visibleItems.length > 0) &&
                        this.props.menuTitle && (
                          <StyledItem>
                            <Text
                              textStyle="headline6"
                              usePxFontSize={this.props.usePxUnits}
                            >
                              {this.props.menuTitle}
                            </Text>
                          </StyledItem>
                        )}

                      <ul>
                        {this.props.isLoading && visibleItems.length === 0 && (
                          <StyledItem>
                            {this.props.loadingText || (
                              <Text
                                priority="secondary"
                                isUncropped
                                usePxFontSize={this.props.usePxUnits}
                              >
                                {this.props.loadingText}
                              </Text>
                            )}
                          </StyledItem>
                        )}
                        {visibleItems.map((item, index) => {
                          const isActive = highlightedIndex === index;
                          const isSelected = Boolean(
                            this.props.selectedItems.find(
                              ({ id }) => id === item.id,
                            ),
                          );

                          return (
                            <ActionableStyledItem
                              as="li"
                              key={item.id}
                              isActive={isActive}
                              isSelected={isSelected}
                              {...getItemProps({ index, item })}
                            >
                              {this.props.renderItem(item, {
                                isActive,
                                isSelected,
                              })}
                            </ActionableStyledItem>
                          );
                        })}
                      </ul>
                    </>
                  )}
                </StyledMenu>
              </StyledWrapper>
            </div>
          );
        }}
      </Downshift>
    );
  }
}

export type SingleSelectProps<I = Item> = BaseProps<I> & {
  clearInputValueOnSelection?: boolean;
  onSelect: (item: I | null, downshift: ControllerStateAndHelpers<I>) => void;
  selectedItem: I | null;
};

const SingleSelect = <I extends Item = Item>({
  onSelect,
  selectedItem,
  ...restProps
}: SingleSelectProps<I>) => (
  <AutocompleteInput
    {...restProps}
    onSelectionChange={(selectedItems, downshift) =>
      onSelect(
        selectedItems.length === 0
          ? null
          : selectedItems[selectedItems.length - 1],
        downshift,
      )
    }
    selectedItems={selectedItem ? [selectedItem] : []}
  />
);

export type MultiSelectProps<I = Item> = BaseProps<I> & {
  onSelectionChange: (
    selectedItems: I[],
    downshift: ControllerStateAndHelpers<I>,
  ) => void;
  selectedItems: I[];
};

const MultiSelect = <I extends Item = Item>(props: MultiSelectProps<I>) => (
  <AutocompleteInput clearInputValueOnSelection {...props} />
);

export default { SingleSelect, MultiSelect };
