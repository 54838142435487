import transparentize from "polished/lib/color/transparentize";
import styled from "styled-components";

import { InsetSquishProps } from "../InsetSquish";
import Spacings from "../Spacings";
import { fontSizeByTextStyle } from "../Text";

export type InputBoxProps = InsetSquishProps & {
  invalid?: boolean;
};

const InputBox = styled(Spacings.InsetSquish)<InputBoxProps>`
  background: ${(props) => props.theme.palette.white};
  border: ${(props) => props.theme.borders.width.small}px solid
    ${(props) =>
      props.invalid
        ? props.theme.palette.error.main
        : props.theme.palette.borders.main};
  border-radius: ${(props) => props.theme.borders.radius.medium}px;
  box-sizing: border-box;
  font-size: ${(props) => fontSizeByTextStyle(props.theme).bodyProlonged}rem;
  width: 100%;
  outline: none;

  &:hover,
  &:focus,
  &:focus-within {
    border-color: ${(props) =>
      props.invalid
        ? props.theme.palette.error.main
        : props.theme.palette.brand.main};
  }

  &:focus,
  &:focus-within {
    box-shadow: 0 0 0 3px
      ${(props) =>
        props.invalid
          ? transparentize(0.7, props.theme.palette.error.main)
          : transparentize(0.7, props.theme.palette.brand.main)};
  }
`;

export default InputBox;
