import styled from "styled-components";

import { mapScaleToPixels, SpacingScales } from "../Spacings/helpers";

export type InlineProps = {
  alignItems?: string;
  scale?: SpacingScales;
  usePxUnits?: boolean;
};

const Inline = styled.div<InlineProps>`
  display: flex;
  align-items: ${(props) => props.alignItems};
  > * + * {
    margin: 0 0 0
      ${(props) =>
        mapScaleToPixels(props.scale!, props.theme, props.usePxUnits)};
  }
`;

Inline.defaultProps = {
  scale: "small",
};

export default Inline;
